import * as React from 'react';
import { setHealthCategory } from '../actions/consultation/actions';


const connect = require('react-redux').connect;

class UpperRespiratory extends React.Component {
    constructor(){
        super();
    }
    componentDidMount() {
        this.props.onSetHealthCategory('5e61e6ac6c96a6266fcff94a', 'adult')
        this.props.history.push('/subhealthcategory/5e61e6ac6c96a6266fcff94a')
    }
    render() {
        return (
            <div></div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetHealthCategory: (id, type) => {
          dispatch(setHealthCategory(id, type));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpperRespiratory);
