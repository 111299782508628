import * as React from 'react';
import {Link} from 'react-router-dom'
import { setHealthCategory } from '../../../actions/consultation/actions';


const connect = require('react-redux').connect;

class Vaccination extends React.Component {
    constructor(){
        super();
        this.handleInvitation = this.handleInvitation.bind(this);
        this.handleVaccinationBtn = this.handleVaccinationBtn.bind(this);
    }
    handleInvitation(e) {
        e.preventDefault();
        window.open("https://api.whatsapp.com/send?text=Feeling%20unwell%3F%20You%20can%20self-treat%20minor%20health%20problems%20like%20cold%2C%20cough%2C%20fever%20%26%20many%20such%20conditions%20in%20just%204%20clicks%20before%20visiting%20a%20doctor.%0A%0ATry%20SOCTOR.%20It's%20free.%0Awww.soctor.com", "_blank")
    }
    handleVaccinationBtn(e) {
        e.preventDefault();
        this.props.onSetHealthCategory('60040d5b4b1c673f9aee7b94', 'adult')
        this.props.history.push('/subhealthcategory/60040d5b4b1c673f9aee7b94')
    }
    render() {
        return (
            <div>
                <section className="landing-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-12 text-left">
                                <div className="vertical-center">
                                    <h2>Got side effects to COVID vaccine?</h2>
                                    <h2>Become a SELF-DOCTOR & take care of yourself in 4 clicks.</h2>
                                    <h5>Self-medication advice on how to safely use non-prescription (OTC) medicines to self-treat side effects of COVID vaccine, including pain, swelling, fever & more. Its easy. Its free.</h5>
                                    <button type="button" onClick={this.handleVaccinationBtn} className="btn btn-pink web">Generate my self-medication advice now</button>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 text-center">
                                <img src="assets/covid/covid-vaccination-banner.svg" className="img-vertical-center covid-vaccination-banner"/>
                                <button onClick={this.handleVaccinationBtn} type="button" className="btn btn-pink mt-3 mobile">Generate my self-medication advice now</button>
                            </div>
                        </div>
                    </div>
                    
                </section>
                <section className="img-list" id="whatIsSoctor">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2>What is soctor?</h2>
                            </div>
                        </div>
                        <div className="row align-items-center mt-md-3 pt-5 justify-content-center">
                            <div className="col-md-6 col-12">
                                <img className="img-all" src="assets/home-screen/Group 1589.svg" alt="" />
                            </div>
                            <div className="col-md-6 col-12 mt-5 mt-md-0">
                                <ul className="list-items">
                                    <li>Do-It-Yourself service</li>
                                    <li>Designed by doctors</li>
                                    <li>Enter your symptoms</li>
                                    <li>Get algorithm generated self-care advice</li>
                                    <li>To self-manage minor health problems</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="three-items how-it-works">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2>How it works</h2>
                            </div>
                        </div>
                        <div className="row mt-md-3 pt-5" id="how-it-works-box">
                            <div className="col-12 col-md-4">
                                <div className="details mt-4 box">
                                    <h3>1. Generate advice</h3>
                                    <h5 className="mt-3">Enter your symptoms/complaints and generate free self-medication advice</h5>
                                    <img src="assets/home-screen/Group 1403.png" alt="" />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="details mt-4 box">
                                    <h3>2. Get medicines</h3>
                                    <h5 className="mt-3">As per advice grab medicines available at home or purchase from nearby pharmacy</h5>
                                    <img src="assets/home-screen/Group 1426.svg" alt="" />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="details mt-4 box">
                                    <h3>3. Take medicines</h3>
                                    <h5 className="mt-3">Take medicines as per instruction & get relief from health problems</h5>
                                    <img src="assets/home-screen/Group 1431.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="img-list">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2>Its safe & its easy</h2>
                            </div>
                        </div>
                        <div className="row align-items-center mt-md-3 pt-5 justify-content-center">
                            <div className="col-md-6 col-12 mt-5 mt-md-0">
                                <img className="img-all" src="assets/home-screen/Group 1862.svg" alt="" />
                            </div>
                            <div className="col-md-6 col-12">
                                <ul className="list-items">
                                    <li>Self-care advice developed by doctors</li>
                                    <li>Reviewed by expert doctors for safety & efficacy</li>
                                    <li>Its easy. Just 4 clicks</li>
                                </ul>
                                <Link to="/" type="button" className="btn btn-pink mt-3">I want to know more about SOCTOR</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetHealthCategory: (id, type) => {
          dispatch(setHealthCategory(id, type));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vaccination);
