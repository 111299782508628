import { actionTypes as at } from './constants';

export const fetchHealthCategories = () => {
    return {
        type: at.FETCH_HEALTH_CATEGORIES
    };
};

export const fetchHealthCategoriesSuccess = (value) => {
    return {
        type: at.FETCH_HEALTH_CATEGORIES_SUCCESS,
        payload: value
    };
};

export const fetchHealthCategoriesError = () => {
    return {
        type: at.FETCH_HEALTH_CATEGORIES_ERROR
    };
};

export const fetchSubHealthCategories = (data) => {
    return {
        type: at.FETCH_SUB_HEALTH_CATEGORIES,
        payload: data
    };
};

export const fetchSubHealthCategoriesSuccess = (value) => {
    return {
        type: at.FETCH_SUB_HEALTH_CATEGORIES_SUCCESS,
        payload: value
    };
};

export const fetchSubHealthCategoriesError = () => {
    return {
        type: at.FETCH_SUB_HEALTH_CATEGORIES_ERROR
    };
};

export const setHealthCategory = (id, type) => {
    return {
        type: at.SET_HEALTH_CATEGORY,
        payload: {id, type}
    };
};

export const setSubHealthCategory = (id) => {
    return {
        type: at.SET_SUB_HEALTH_CATEGORY,
        payload: id
    };
};


export const fetchDiseases = () => {
    return {
        type: at.FETCH_DISEASES
    };
};

export const fetchDiseasesSuccess = (value) => {
    return {
        type: at.FETCH_DISEASES_SUCCESS,
        payload: value
    };
};

export const fetchDiseasesError = () => {
    return {
        type: at.FETCH_DISEASES_ERROR
    };
};

export const fetchSymptoms = (data) => {
    return {
        type: at.FETCH_SYMPTOMS,
        payload: data
    };
};

export const fetchSymptomsSuccess = (value) => {
    return {
        type: at.FETCH_SYMPTOMS_SUCCESS,
        payload: value
    };
};

export const fetchSymptomsError = () => {
    return {
        type: at.FETCH_SYMPTOMS_ERROR
    };
};

export const fetchPrescription = (data) => {
    return {
        type: at.FETCH_PRESCRIPTION,
        payload: data
    };
};

export const fetchPrescriptionSuccess = (value) => {
    return {
        type: at.FETCH_PRESCRIPTION_SUCCESS,
        payload: value
    };
};


export function clearPrescription() {
    return {
        type: at.CLEAR_PRESCRIPTION
    };
}
  
export function emailPrescription(data) {
    return {
        type: at.EMAIL_PRESCRIPTION,
        payload: data
    };
}
  
export function emailPrescriptionSuccess(data) {
    return {
        type: at.EMAIL_PRESCRIPTION_SUCCESS,
        payload: data
    };  
}
  
export function downloadPrescription(data) {
    return {
        type: at.DOWNLOAD_PRESCRIPTION,
        payload: data
    };
}

export function downloadPrescriptionSuccess(data) {
    return {
        type: at.DOWNLOAD_PRESCRIPTION_SUCCESS,
        payload: data
    };  
}

export function updateConsultationData(data) {
    return {
        type: at.UPDATE_CUSTOMER_CONSULTATION_DATA,
        payload: data
    };  
}


export function resetConsultation() {
    return {
        type: at.RESET_PRESCRIPTION
    };
}