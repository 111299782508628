import { actionTypes as at } from './constants';

export const login = (values) => {
    return {
        type: at.USER_LOGIN,
        payload: values
    };
};

export const loginSuccess = (result) => {
    return {
        type: at.USER_LOGIN_SUCCESS,
        payload: result
    };
};

export const loginError = (error) => {
    return {
        type: at.USER_LOGIN_ERROR,
        payload: error
    };
};

export const logout = () => {
    return {
        type: at.USER_LOGOUT
    };
};

export const logoutSuccess = () => {
    return {
        type: at.USER_LOGOUT_SUCCESS
    };
};


export const setLoading = (result) => {
    return {
        type: at.SET_LOADING,
        payload: result
    };
};


export const googleLoginOperation = (data) => {
    return {
        type: at.HANDLE_GOOGLE_LOGIN,
        payload: data
    };
};


export const facebookLoginOperation = (data) => {
    return {
        type: at.HANDLE_FACEBOOK_LOGIN,
        payload: data
    };
};


export const otpLoginOperation = (data) => {
    return {
        type: at.HANDLE_OTP_LOGIN,
        payload: data
    };
};

export const validateOtp = (data) => {
    return {
        type: at.OTP_VALIDATION,
        payload: data
    };
};

export const otpInitiated = () => {
    return {
        type: at.OTP_INITIATED,
    };
};

export const otpInitialisationFail = () => {
    return {
        type: at.OTP_INTITIALISATION_FAILED,
    };
};

export const updateLogMessage = (message) => {
    return {
        type: at.UPDATE_LOG_MESSAGE,
        payload: message
    };
};


export const verifyEmail = (data) => {
    return {
        type: at.VERIFY_EMAIL,
        payload: data
    };
};

export const verifyEmailSuccess = (data) => {
    return {
        type: at.VERIFY_EMAIL_SUCCESS,
        payload: data
    };
};

export const verifyMobile = (data) => {
    return {
        type: at.VERIFY_MOBILE_NUMBER,
        payload: data
    };
};

export const verifyMobileSuccess = (data) => {
    return {
        type: at.VERIFY_MOBILE_NUMBER_SUCCESS,
        payload: data
    };
};

export const updateHeaderMessageBox = (status) => {
    return {
        type: at.UPDATE_HEADER_MESSAGE_BOX,
        payload: status
    };
};