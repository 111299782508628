import React, { Component } from 'react';
import { connect } from 'react-redux';

import { clearPrescription, emailPrescription, downloadPrescription, downloadPrescriptionSuccess } from '../../actions/consultation/actions';

// import styles from './styles';

class Prescription extends Component {
    static getDerivedStateFromProps(nextProps, state) {
        return {
            user: nextProps.user,
            emailSent: nextProps.emailSent,
            downloadStatus: nextProps.downloadStatus
        }
    }
    constructor() {
        super();
        this.state = {
            prescriptionData: {},
            emailAddress: '',
            downloadProgress: false,
            downloadStatus: false,
            user: {}
        }
        this.sendEmail = this.sendEmail.bind(this);
    }
    componentDidUpdate(){
        if(this.state.downloadStatus && this.state.downloadProgress) {
            this.setState({
                downloadProgress: false,
                downloadStatus: false
            }, () => {
                this.props.onDownloadPrescriptionSuccess(false)
            })
        }
    }
    componentDidMount(){
        window.scrollTo(0,0);
        const {prescriptionData} = this.props;
        if(Object.keys(prescriptionData).length === 0) {
            this.props.history.push('/')
        } else if(prescriptionData.reportId) {
            this.sendEmail();
        }
    }
    componentWillUnmount() {
        if(this.state.user && this.state.user.mobileVerified && this.state.user.emailVerified) {
            this.props.clearPrescription();
        }
    }
    sendEmail() {
        if(this.state.user && this.state.user.email) {
            this.props.emailPrescription({reportId: this.props.prescriptionData.reportId, emailAddress: this.state.user.email, prescriptionData: this.props.prescriptionData })
        }
    }

    downloadPDF(event) {
        event.preventDefault();
        this.setState({
            downloadProgress: true
        }, () => {
            this.props.emailPrescription({reportId: this.props.prescriptionData.reportId, prescriptionData: this.props.prescriptionData })
        })
    }

    render() {
        const { prescriptionData, emailSent } = this.props;
        return (
            <div className="prescription">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-12">
                        {(this.state.user && this.state.user.username) || prescriptionData.medicalAttention 
                        ?
                            (
                            <div>
                                <div className="content-box user-details">
                                    {this.state.user && this.state.user.username 
                                    ?
                                    (
                                        <div>
                                            <span>Self-Care advice for</span>
                                            <h1>{this.state.user.username}</h1>
                                        </div>
                                    )
                                    : undefined}
                                </div>
                                {prescriptionData.medicalAttention ? 
                                    <div className="content-box user-details">
                                        <span className="medication"> <img src="assets/icons/siren.svg"  style={{ width: '20px' }} /> &nbsp; Some of your symptoms need medical attention.</span> 
                                    </div>
                                : null}
                            </div>
                            ) 
                        : null}
                            {prescriptionData && prescriptionData.prescription && prescriptionData.prescription.length > 0 && prescriptionData.prescription.map((prescription,i) => {
                                if(prescription) {
                                    let usageTimes = 0;
                                    if(prescription.standardDosage.morning) {
                                        usageTimes += 1;
                                    }
                                    if(prescription.standardDosage.midday) {
                                        usageTimes += 1;
                                    }
                                    if(prescription.standardDosage.evening) {
                                        usageTimes += 1;
                                    }
                                    if(prescription.standardDosage.bedtime) {
                                        usageTimes += 1;
                                    }
                                    return (
                                        <div key={i} className="content-box">
                                            <div className="block-head">
                                                <h5>{prescription.brandName} <span style={{textTransform: 'uppercase'}}> {prescription.drugForm.name}</span></h5>
                                                <span className="generic-name">
                                                    {prescription.genericName}
                                                </span>
                                                <span className="dosage">
                                                    {prescription.standardDosage.description}
                                                </span>
                                            </div>
                                            <div className="dosage-per-day">
                                                {prescription.alternativeDosage ?
                                                    <div className="timings">
                                                        <div className="alternativeDosage">
                                                        {prescription.alternativeDosage}
                                                        </div>
                                                    </div>
                                                :
                                                    <div className="timings">
                                                        <div className="morning">
                                                            <div className="time">
                                                                <img src="assets/icons/morning.svg" alt="" />
                                                                <span>6 – 8 AM<br />MORNING</span>
                                                            </div>
                                                            <div className="syrup-quantity">{prescription.standardDosage.morning ?  `${prescription.standardDosage.morning} ${prescription.measure}`: '-'}</div>
                                                        </div>
                                                        <div className="midday">
                                                            <div className="time">
                                                                <img src="assets/icons/midday.svg" alt="" />
                                                                <span>11 – 1 PM<br />MID DAY</span>
                                                            </div>
                                                            <div className="syrup-quantity">{prescription.standardDosage.midday ?  `${prescription.standardDosage.midday} ${prescription.measure}`: '-'}</div>
                                                        </div>
                                                        <div className="evening">
                                                            <div className="time">
                                                                <img src="assets/icons/evening.svg" alt="" />
                                                                <span>4 – 6 PM<br />EVENING</span>
                                                            </div>
                                                            <div className="syrup-quantity">{prescription.standardDosage.evening ?  `${prescription.standardDosage.evening } ${prescription.measure}`: '-'}</div>
                                                        </div>
                                                        <div className="bedtime">
                                                            <div className="time">
                                                                <img src="assets/icons/bedtime.svg" alt="" />
                                                                <span>9 – 11 PM<br />BEDTIME</span>
                                                            </div>
                                                            <div className="syrup-quantity">{prescription.standardDosage.bedtime ?  `${prescription.standardDosage.bedtime } ${prescription.measure}`: '-'}</div>
                                                        </div>
                                                    </div>
                                                    }
                                                <div className="message">
                                                    <ul>
                                                        {prescription.realtionWithFood && <li>{prescription.realtionWithFood} </li>}
                                                        {prescription.drivingAlcoholCaution && <li>{prescription.drivingAlcoholCaution}</li>}
                                                        {prescription.alcoholCaution && <li>Do not consume alcohol</li>}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                            {prescriptionData && prescriptionData.nonDrugManagement ? 
                                <div className="content-box user-details">
                                    <div className="row">
                                        <div className="col-12">
                                            {prescriptionData.nonDrugManagement.homeRemedies ?
                                                <div className="need-to-do">
                                                    <h3 className="title"><img src="assets/icons/homeremedies.svg" alt="" className="mr-2" /> Home remedies / Diet / Lifestyle</h3>
                                                    <div dangerouslySetInnerHTML={{ __html: prescriptionData.nonDrugManagement.homeRemedies }} />
                                                </div>
                                            : null}
                                            {prescriptionData.nonDrugManagement.needToKnow ?
                                            <div className="need-to-do">
                                                <h3 className="title"><img src="assets/icons/needtoknow.svg" alt="" className="mr-2" /> Need to know</h3>
                                                <div dangerouslySetInnerHTML={{ __html: prescriptionData.nonDrugManagement.needToKnow }} />
                                            </div>
                                            : null}
                                            {prescriptionData.nonDrugManagement.howToPrevent ?
                                                <div className="need-to-do">
                                                    <h3 className="title"><img src="assets/icons/howto.svg" alt="" className="mr-2" /> How to prevent</h3>
                                                    <div dangerouslySetInnerHTML={{ __html: prescriptionData.nonDrugManagement.howToPrevent }} />
                                                </div>
                                            : null}
                                            {prescriptionData.nonDrugManagement.whatNotToDo ?
                                                <div className="need-to-do">
                                                    <h3 className="title"><img src="assets/icons/what_hand.svg" alt="" className="mr-2" /> What not to do</h3>
                                                    <div dangerouslySetInnerHTML={{ __html: prescriptionData.nonDrugManagement.whatNotToDo }} />
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            : null }
                            <div className="row justify-content-center mt-4 mb-4">
                                <div className="col-md-12 col-12 action-buttons">
                                    <button onClick={e => this.downloadPDF(e)} className="btn btn-pink downloadbtn">
                                        {this.state.downloadProgress ?
                                            <div className="spinner-border text-light" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            : 
                                             <p className="mb-0"><img className="download-icon" src="assets/icons/download.svg" /> Download this advice</p>
                                        }
                                       
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const consultationState = state.get('consultationReducer').toJS();
    const loginReducer = state.get('loginReducer').toJS();
    return {
        prescriptionData: consultationState.prescription,
        emailSent: consultationState.emailSent,
        user: loginReducer.user,
        downloadStatus: consultationState.downloadStatus
    }
}

const mapDispatchToProps = dispatch => ({
    clearPrescription: () => dispatch(clearPrescription()),
    emailPrescription: (data) => dispatch(emailPrescription(data)),
    downloadPrescription: (data) => dispatch(downloadPrescription(data)),
    onDownloadPrescriptionSuccess: (data) => dispatch(downloadPrescriptionSuccess(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Prescription);