import * as React from 'react';
import {Link} from 'react-router-dom'


const connect = require('react-redux').connect;

class Home extends React.Component {
    static getDerivedStateFromProps(props) {
        return {
            showHeaderBox: props.showHeaderBox
        }
    }
    constructor(){
        super();
        this.state = {
            showHeaderBox: false
        };
        this.handleInvitation = this.handleInvitation.bind(this);
        this.computeCovidLinkVisibility = this.computeCovidLinkVisibility.bind(this);
    }
    handleInvitation(e) {
        e.preventDefault();
        window.open("https://api.whatsapp.com/send?text=Feeling%20unwell%3F%20You%20can%20self-treat%20minor%20health%20problems%20like%20cold%2C%20cough%2C%20fever%20%26%20many%20such%20conditions%20in%20just%204%20clicks%20before%20visiting%20a%20doctor.%0A%0ATry%20SOCTOR.%20It's%20free.%0Awww.soctor.com", "_blank")
    }
    computeCovidLinkVisibility(){
        const currLocation = this.props.location.pathname;
        if(currLocation === '/') {
            return false
        } else return true
    }
    render() {
        return (
            <div>
                <section className="landing-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-12 text-left">
                                <div className={`vertical-center ${this.state.showHeaderBox && !this.computeCovidLinkVisibility() ? 'addMessageBoxMargintTop' : ''}`}>
                                    <h2>Not well? Become SELF-DOCTOR,</h2>
                                    <h2>Take care of yourself in 4 clicks</h2>
                                    <h5>Self-medication advice on how to safely use non-prescription (OTC) medicines to self-manage minor health problems like cold, cough, fever, loose motions & many such conditions. Its easy. Its free.</h5>
                                    <Link to="/consultation" type="button" className="btn btn-pink web">Generate my self-medication advice now</Link>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 text-center">
                                <img src="assets/home-screen/Group 1863.svg" className="img-vertical-center"/>
                                <Link to="/consultation" type="button" className="btn btn-pink mt-3 mobile">Generate my self-medication advice now</Link>
                            </div>
                        </div>
                    </div>
                    
                </section>
                <section className="img-list" id="whatIsSoctor">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2>What is soctor?</h2>
                            </div>
                        </div>
                        <div className="row align-items-center mt-md-3 pt-5 justify-content-center">
                            <div className="col-md-6 col-12">
                                <img className="img-all" src="assets/home-screen/Group 1589.svg" alt="" />
                            </div>
                            <div className="col-md-6 col-12 mt-5 mt-md-0">
                                <ul className="list-items">
                                    <li>Do-It-Yourself service</li>
                                    <li>Designed by doctors</li>
                                    <li>Enter your symptoms</li>
                                    <li>Get algorithm generated self-care advice</li>
                                    <li>To self-manage minor health problems</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="three-items how-it-works">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h3>Faced these situations?</h3>
                                <h2>Then Soctor is for you</h2>
                            </div>
                        </div>
                        <div className="row pt-5" id="situations-box">
                            <div className="col-6 col-md-3">
                                <img src="assets/home-screen/Group 1919.svg" alt="" />
                            </div>
                            <div className="col-6 col-md-3">
                                <img src="assets/home-screen/Group 1918.svg" alt="" />
                            </div>
                            <div className="col-6 col-md-3">
                                <img src="assets/home-screen/Group 1917.svg" alt="" />
                            </div>
                            <div className="col-6 col-md-3">
                                <img src="assets/home-screen/Group 1920.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="three-items how-it-works">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2>How it works</h2>
                            </div>
                        </div>
                        <div className="row mt-md-3 pt-5" id="how-it-works-box">
                            <div className="col-12 col-md-4">
                                <div className="details mt-4 box">
                                    <h3>1. Generate advice</h3>
                                    <h5 className="mt-3">Enter your symptoms/complaints and generate free self-medication advice</h5>
                                    <img src="assets/home-screen/Group 1403.png" alt="" />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="details mt-4 box">
                                    <h3>2. Get medicines</h3>
                                    <h5 className="mt-3">As per advice grab medicines available at home or purchase from nearby pharmacy</h5>
                                    <img src="assets/home-screen/Group 1426.svg" alt="" />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="details mt-4 box">
                                    <h3>3. Take medicines</h3>
                                    <h5 className="mt-3">Take medicines as per instruction & get relief from health problems</h5>
                                    <img src="assets/home-screen/Group 1431.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="img-list">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2>Its safe & its easy</h2>
                            </div>
                        </div>
                        <div className="row align-items-center mt-md-3 pt-5 justify-content-center col-reverse">
                            <div className="col-md-6 col-12 mt-5 mt-md-0">
                                <ul className="list-items">
                                    <li>Self-care advice developed by doctors</li>
                                    <li>Reviewed by expert doctors for safety & efficacy</li>
                                    <li>Its easy. Just 4 clicks</li>
                                </ul>
                            </div>
                            <div className="col-md-6 col-12">
                                <img className="img-all" src="assets/home-screen/Group 1862.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="img-list">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2>Its convenient & its free</h2>
                            </div>
                        </div>
                        <div className="row align-items-center mt-md-3 pt-5 justify-content-center">
                            <div className="col-md-7 col-12">
                                <img className="img-all" src="assets/home-screen/Group 1809.svg" alt="" />
                            </div>
                            <div className="col-md-5 col-12 mt-5 mt-md-0">
                                <ul className="list-items">
                                    <li>No fee, No appointment, No waiting</li>
                                    <li>Save time & money</li>
                                    <li>Limit severity of illness & suffering</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="list-items">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2>Say yes to responsible self-medication</h2>
                            </div>
                        </div>
                        <div className="row align-items-center mt-md-3 pt-5">
                            <div className="col-md-6 col-12">
                                <div className="icon-box">
                                    <div className="icon">
                                        <img src="assets/home-screen/yes-to-self-medication.svg" alt="" />
                                    </div>
                                    <div className="content">
                                        <p className="red">Antibiotic resistance</p>
                                        <p className="green">No antibiotics are prescribed</p>
                                    </div>
                                </div>
                                <div className="icon-box">
                                    <div className="icon">
                                        <img src="assets/home-screen/yes-to-self-medication-6.svg" alt="" />
                                    </div>
                                    <div className="content">
                                        <p className="red">Misdiagnosis of illness</p>
                                        <p className="green">No diagnosis is made</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="icon-box">
                                    <div className="icon">
                                        <img src="assets/home-screen/yes-to-self-medication-4.svg" alt="" />
                                    </div>
                                    <div className="content">
                                        <p className="red">Incorrect choice of therapy</p>
                                        <p className="green">Right therapies developed by doctors</p>
                                    </div>
                                </div>
                                <div className="icon-box">
                                    <div className="icon">
                                        <img src="assets/home-screen/yes-to-self-medication-3.svg" alt="" />
                                    </div>
                                    <div className="content">
                                        <p className="red">Incorrect dosage</p>
                                        <p className="green">Right dose & instructions</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="img-list">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2>Helping you to help yourself</h2>
                            </div>
                        </div>
                        <div className="row align-items-center mt-md-3 pt-5 col-reverse">
                            <div className="col-md-6 col-12 mt-md-0 mt-5">
                                <ul className="list-items">
                                    <li>Unqualified pharmacist advice can be harmful</li>
                                    <li>Dr Google advice may not be relevant</li>
                                    <li>Your doctor is the best person to treat you</li>
                                    <li>Soctor is safe way to self-medicate before visiting a doctor</li>
                                </ul>
                                <Link to="/consultation" type="button" className="btn btn-pink mt-5 get-advice-btn" style={{fontSize: '14px'}}> Get my doctor-grade advice now</Link>
                            </div>
                            <div className="col-md-6 col-12">
                                <img className="img-all" src="assets/home-screen/Group 1523.png" alt="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                            </div>
                        </div>
                    </div>
                </section>
                <section className="send-invitaiton">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-12">
                                <img className="img-all" src="assets/home-screen/Group 1861.png" alt="" />
                            </div>
                            <div className="col-md-6 col-12 content">
                                <h3>Let your friends, colleagues & relatives benefit from responsible
                                    self-care. Invite them</h3>
                                <button onClick={this.handleInvitation} type="button" className="btn btn-pink mt-5 green">
                                    <i className="mr-2 bi bi-whatsapp"></i> Send Invitation
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const loginState = state.get('loginReducer').toJS()
    return {
        showHeaderBox: loginState.showHeaderBox
    };
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
