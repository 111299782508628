import * as React from 'react';
import {Link} from 'react-router-dom'


const connect = require('react-redux').connect;

class Contactus extends React.Component {
    render() {
        return (
            <div>
                <section className="inner-section blue contact">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div class="col-md-auto about-intro">
                                <h3>Hello</h3>
                                <p>Any help you need, please do not hesitate to contact us.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-section">
                    <div className="container">
                        <div className="row contact-section align-items-center justify-content-center">
                            <div className="col-md-6 col-12">
                                <div className="content text-center">
                                    <p><i style={{marginRight:'5px',display: 'inline-flex'}} className="bi bi-envelope-open"></i> help@soctor.com</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="content text-center">
                                    <p><i style={{marginRight:'5px',display: 'inline-flex'}} className="bi bi-phone"></i> 9493808080</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contactus);
