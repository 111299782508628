import { actionTypes as at } from '../actions/profile/constants';
import { getPatientDetailsSuccess } from '../actions/profile/actions';
import { takeEvery, put } from 'redux-saga/effects';
import axios from 'axios';

function* getPatient(data){
  const { payload } = data;
  try {
    const { data={} } = yield axios.get("/patients/"+payload);
    if(data.success) {
        yield put(getPatientDetailsSuccess(data.data));
    }
  }catch(err) {
    console.log(err);
    return null;
  }
}

export default function* loginSaga() {
  try {
    yield [
        takeEvery(at.GET_PATIENT_DETAILS, getPatient),
    ];
  } catch (error) {
    return;
  }
}
