import { fromJS } from 'immutable';
import { actionTypes as at } from '../actions/profile/constants'

const initialState = fromJS({
    patient: {}
});

export default (state = initialState, action) => {
  switch (action.type) {
    case at.GET_PATIENT_DETAILS_SUCCESS:
      return state
        .set('patient', action.payload)
    
    default:
      return state;
  }
};
