import { actionTypes as at } from '../actions/login/constants';
import {  loginError, logoutSuccess, loginSuccess, otpInitiated, otpInitialisationFail, verifyEmailSuccess, verifyMobileSuccess } from '../actions/login/actions';
import { takeEvery, put } from 'redux-saga/effects';
import setAuthorizationToken from '../utils/setAuthToken'
import axios from 'axios';
import { toast } from "react-toastify";
var jwt = require('jwt-simple');


function* loginUser(data) {
    // const { payload } = data;
    // try {
    //     // const { data } = yield axios.post('/users/login', payload);
    //     // if (!userdata.success) {
    //     //     yield put(loginError(userdata.message));
    //     // } else {
    //     //     setAuthorizationToken(userdata.token);
    //     //     localStorage.setItem('token', userdata.token)
    //     //     yield put(loginSuccess(true));
    //     // }   
    // } catch(err) {
    //     console.log(err);
    //     return null;
    // }  
}

function* socialLogin(data){
  const { payload } = data;
  try {
    const { data } = yield axios.post("/auth/social", payload);
    if(data.success) {
      let encryptedData = jwt.encode(data, process.env.REACT_APP_LOGIN_SECRET_KEY);
      setAuthorizationToken(data.token);
      localStorage.setItem('token', encryptedData)
      yield put(loginSuccess(data));
    }
  }catch(err) {
    yield put(loginError(err));
    return null;
  }
}

function* otpLogin(data){
  const { payload } = data;
  try {
    const { data } = yield axios.get("/auth/mobile/"+payload);
    if(data.success) {
      yield put(otpInitiated());
      toast('OTP has sent successfully')
    }
  }catch(err) {
    yield put(otpInitialisationFail());
    toast('OTP Failed. Please try again')
    return null;
  }
}

function* otpValidation(data){
  const { payload } = data;
  try {
    const { data } = yield axios.post("/auth/mobile", payload);
    if(data.success) {
      let encryptedData = jwt.encode(data, process.env.REACT_APP_LOGIN_SECRET_KEY);
      setAuthorizationToken(data.token);
      localStorage.setItem('token', encryptedData)
      yield put(loginSuccess(data));
    } else {
      toast('Invalid OTP. Please try again')
    }
  }catch(err) {
    yield put(loginError(err));
    return null;
  }
}


function* verifyEmail(data){
  const { payload } = data;
  try {
    const { data } = yield axios.post("/patients/verifyemail", payload);
    if(data.success) {
      yield put(verifyEmailSuccess(data.user));
      toast(data.message)
    } else {
      toast(data.message)
    }
  }catch(err) {
    return null;
  }
}


function* verifyMobile(data){
  const { payload } = data;
  try {
    const { data } = yield axios.post("/patients/verifymobile", payload);
    if(data.success) {
      yield put(verifyMobileSuccess(data.user));
      toast(data.message)
    } else {
      toast(data.message)
    }
  }catch(err) {
    return null;
  }
}


function* logoutUser() {
    setAuthorizationToken(false);
    localStorage.removeItem('token');
    yield put(logoutSuccess()); 
}

export default function* loginSaga() {
  try {
    yield [
        takeEvery(at.USER_LOGIN, loginUser),
        takeEvery(at.USER_LOGOUT, logoutUser),
        takeEvery(at.HANDLE_GOOGLE_LOGIN, socialLogin),
        takeEvery(at.HANDLE_FACEBOOK_LOGIN, socialLogin),
        takeEvery(at.HANDLE_OTP_LOGIN, otpLogin),
        takeEvery(at.OTP_VALIDATION, otpValidation),
        takeEvery(at.VERIFY_EMAIL, verifyEmail),
        takeEvery(at.VERIFY_MOBILE_NUMBER, verifyMobile),
    ];
  } catch (error) {
    return;
  }
}
