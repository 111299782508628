export function validateMobile(number) {
    return /^[6-9]\d{9}$/.test(parseInt(number));
}
export const debounce = (func, wait) => {
    let timeout;

    return function executedFunction(...args) {
        const later = () => {
        clearTimeout(timeout);
        func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};