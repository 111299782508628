import { actionTypes as at } from './constants';


export const getPatientDetails = (id) => {
    return {
        type: at.GET_PATIENT_DETAILS,
        payload: id
    };
};

export const getPatientDetailsSuccess = (data) => {
    return {
        type: at.GET_PATIENT_DETAILS_SUCCESS,
        payload: data
    };
};

export const getPatientDetailsError = (data) => {
    return {
        type: at.GET_PATIENT_DETAILS_ERROR,
        payload: data
    };
};