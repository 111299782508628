import { all } from 'redux-saga/effects';

import loginSaga from './sagas/login';
import profileSaga from './sagas/profile'
import consultationSaga from './sagas/consultation';

function* globalSagas() {

  yield all([
    loginSaga(),
    profileSaga(),
    consultationSaga()
  ]);
}

export default globalSagas;
