import * as React from 'react';
import Header from '../partials/header/';
import Footer from '../partials/footer';
import MetaData from '../partials/metadata';
const connect = require('react-redux').connect;


class Layout extends React.Component{
  static getDerivedStateFromProps(nextProps) {
    return {
      loading: nextProps.loading
    }
  }

constructor(props){
    super(props);
    this.state = {
        loading : false
    }
    this.onRouteChanged = this.onRouteChanged.bind(this);
}

onRouteChanged() {
  window.scrollTo(0,0);
}
componentDidUpdate(prevProps) {
  if (this.props.location !== prevProps.location) {
    this.onRouteChanged();
  }
}
render(){
    if(this.state.loading) {
      return (
        <div className="center-loading">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )
    } else {
      const pathname = this.props.location.pathname;
      const children = this.props.children.filter(child => child.props.path === pathname)[0];
      const childProps = children?.props || {};
      return (
              <div className="wrapper">
                <MetaData title={childProps.title} description={childProps.description} keywords={childProps.keywords} />
                <Header {...this.props}/>
                {this.props.children}
                <Footer {...this.props}/>
              </div>
            );
    }
  }
  
}

function mapStateToProps(state) {
  const loginState = state.get('loginReducer').toJS()
  return {
    loading: loginState.loading
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
