import * as React from 'react';
import { logout } from '../../actions/login/actions';

const connect = require('react-redux').connect;

class Login extends React.Component {
    static getDerivedStateFromProps(props) {
        if(props.isAuthorised) {
            props.history.push('/');
        }
        return null;
      }
    constructor(props) {
        super(props);
        this.state ={
            mobileNumber: '',
            otp: ''
        }
    }
    componentDidMount() {
        this.props.onLogout();
        this.props.history.push('/');
    }
    render() {
        return null;
    }
}

function mapStateToProps(state) {
    const loginState = state.get('loginReducer').toJS()
    return {
        isAuthorised: loginState.isAuthorised,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        // toggleTodo: todoId => dispatch(toggleTodo(todoId))
        onLogout: () => {
          dispatch(logout());
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
