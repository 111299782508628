import * as React from 'react';
import {Link} from 'react-router-dom'
import { setHealthCategory } from '../../../actions/consultation/actions';


const connect = require('react-redux').connect;

class CovidTreatments extends React.Component {
    constructor(){
        super();
        this.handleInvitation = this.handleInvitation.bind(this);
        this.handleVaccinationBtn = this.handleVaccinationBtn.bind(this);
    }
    handleInvitation(e) {
        e.preventDefault();
        window.open("https://api.whatsapp.com/send?text=Feeling%20unwell%3F%20You%20can%20self-treat%20minor%20health%20problems%20like%20cold%2C%20cough%2C%20fever%20%26%20many%20such%20conditions%20in%20just%204%20clicks%20before%20visiting%20a%20doctor.%0A%0ATry%20SOCTOR.%20It's%20free.%0Awww.soctor.com", "_blank")
    }
    handleVaccinationBtn(e) {
        e.preventDefault();
        this.props.onSetHealthCategory('5e61e6ac6c96a6266fcff94a', 'adult')
        this.props.history.push('/subhealthcategory/5e61e6ac6c96a6266fcff94a')
    }
    render() {
        return (
            <div>
                <section className="covid-treatment-landing"> 
                </section>
                <div className="prescription covid-treatments">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-12">
                                    <div className="ribbon">
                                        <p>Vitamins used in Covid</p>
                                    </div>
                                    <div className="content-box">
                                        <div className="block-head">
                                            <h5>LIMCEE 500 mg <span style={{textTransform: 'uppercase'}}> TABLET</span></h5>
                                            <span className="generic-name">Ascorbic acid 500 mg</span><span className="dosage">Take 1 pill, 2 times a day by mouth for 15 days</span>
                                        </div>
                                        <div className="dosage-per-day">
                                            <div className="timings">
                                                <div className="morning">
                                                    <div className="time">
                                                        <img src="assets/icons/morning.svg" alt="" />
                                                            <span>6 – 8 AM<br />MORNING</span>
                                                        </div>
                                                    <div className="syrup-quantity">1 Pill</div>
                                                </div>
                                                <div className="midday">
                                                    <div className="time"><img src="assets/icons/midday.svg" alt="" /><span>11 – 1 PM<br/>MID DAY</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="evening">
                                                    <div className="time"><img src="assets/icons/evening.svg" alt="" /><span>4 – 6 PM<br/>EVENING</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="bedtime">
                                                    <div className="time"><img src="assets/icons/bedtime.svg" alt="" /><span>9 – 11 PM<br/>BEDTIME</span></div>
                                                    <div className="syrup-quantity">1 Pill</div>
                                                </div>
                                            </div>
                                            <div className="block-head instructions">
                                                <span className="dosage">To be taken on empty stomach (1 hour before or 2 hours after food)</span>
                                            </div>
                                            <div className="message">
                                                <ul>
                                                    <li>It contains Vitamin C</li>
                                                    <li>How it acts: It enhances cortisol production and  potentiates the anti- inflammatory and endothlial cytoprotective effects of glucocorticoids. It can reduce duration and may reduce conversion fro mild infection to critical phase of COVID</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <div className="block-head">
                                            <h5>ZINCOVIT <span style={{textTransform: 'uppercase'}}> TABLET</span></h5>
                                            <span className="generic-name">Zinc + Multivitamin</span><span className="dosage">Take 1 pill, 1 time a day by mouth for 15 days</span>
                                        </div>
                                        <div className="dosage-per-day">
                                            <div className="timings">
                                                <div className="morning">
                                                    <div className="time">
                                                        <img src="assets/icons/morning.svg" alt="" />
                                                            <span>6 – 8 AM<br />MORNING</span>
                                                        </div>
                                                    <div className="syrup-quantity">1 Pill</div>
                                                </div>
                                                <div className="midday">
                                                    <div className="time"><img src="assets/icons/midday.svg" alt="" /><span>11 – 1 PM<br/>MID DAY</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="evening">
                                                    <div className="time"><img src="assets/icons/evening.svg" alt="" /><span>4 – 6 PM<br/>EVENING</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="bedtime">
                                                    <div className="time"><img src="assets/icons/bedtime.svg" alt="" /><span>9 – 11 PM<br/>BEDTIME</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                            </div>
                                            <div className="block-head instructions">
                                                <span className="dosage">To be taken on empty stomach (1 hour before or 2 hours after food)</span>
                                            </div>
                                            <div className="message">
                                                <ul>
                                                    <li>It contains Zinc & Multivitamins</li>
                                                    <li>How it acts: Zinc may have Antiviral Activity,  whether by improving immune cell function that counters viral infections or by reducing the ability of viruses to multiply</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <div className="block-head">
                                            <h5>TAYO 60K <span style={{textTransform: 'uppercase'}}> TABLET</span></h5>
                                            <span className="generic-name">Cholecalciferol 60000 IU</span><span className="dosage">Take 1 pill, Once weekly for 8 weeks</span>
                                        </div>
                                        <div className="dosage-per-day">
                                            <div className="timings">
                                                <div className="morning">
                                                    <div className="time">
                                                        <img src="assets/icons/morning.svg" alt="" />
                                                            <span>6 – 8 AM<br />MORNING</span>
                                                        </div>
                                                    <div className="syrup-quantity">1 Pill</div>
                                                </div>
                                                <div className="midday">
                                                    <div className="time"><img src="assets/icons/midday.svg" alt="" /><span>11 – 1 PM<br/>MID DAY</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="evening">
                                                    <div className="time"><img src="assets/icons/evening.svg" alt="" /><span>4 – 6 PM<br/>EVENING</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="bedtime">
                                                    <div className="time"><img src="assets/icons/bedtime.svg" alt="" /><span>9 – 11 PM<br/>BEDTIME</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                            </div>
                                            <div className="block-head instructions">
                                                <span className="dosage">To be taken after food</span>
                                            </div>
                                            <div className="message">
                                                <ul>
                                                    <li>It contains Vitamin D</li>
                                                    <li>How it acts: It may help boost our bodies'  natural defense against viruses and bacteria. It may help prevent an exaggerated inflammatory response, which has been shown to contribute to severe illness in some people with COVID-19</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="ribbon">
                                        <p>Anti-infectives used in Covid</p>
                                    </div>
                                    <div className="content-box">
                                        <div className="block-head">
                                            <h5>DOXTSL 100 mg <span style={{textTransform: 'uppercase'}}> TABLET</span></h5>
                                            <span className="generic-name">Doxycycline 100 mg</span><span className="dosage">Take 1 pill, twice daily for 5 days</span>
                                        </div>
                                        <div className="dosage-per-day">
                                            <div className="timings">
                                                <div className="morning">
                                                    <div className="time">
                                                        <img src="assets/icons/morning.svg" alt="" />
                                                            <span>6 – 8 AM<br />MORNING</span>
                                                        </div>
                                                    <div className="syrup-quantity">1 Pill</div>
                                                </div>
                                                <div className="midday">
                                                    <div className="time"><img src="assets/icons/midday.svg" alt="" /><span>11 – 1 PM<br/>MID DAY</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="evening">
                                                    <div className="time"><img src="assets/icons/evening.svg" alt="" /><span>4 – 6 PM<br/>EVENING</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="bedtime">
                                                    <div className="time"><img src="assets/icons/bedtime.svg" alt="" /><span>9 – 11 PM<br/>BEDTIME</span></div>
                                                    <div className="syrup-quantity">1 Pill</div>
                                                </div>
                                            </div>
                                            <div className="block-head instructions">
                                                <span className="dosage">To be taken after food</span>
                                            </div>
                                            <div className="message">
                                                <ul>
                                                    <li>It is an Antibiotic</li>
                                                    <li>How it acts- It has anti-bacterial action which  helps in preventing secondary bacterial infection. It also exhibits anti-inflammatory effects along with anti-viral activity against RNA viruses.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <div className="block-head">
                                            <h5>AZITHRAL 500 mg <span style={{textTransform: 'uppercase'}}> TABLET</span></h5>
                                            <span className="generic-name">Azithromycin 500 mg</span><span className="dosage">Take 1pill, Once daily for 5 days</span>
                                        </div>
                                        <div className="dosage-per-day">
                                            <div className="timings">
                                                <div className="morning">
                                                    <div className="time">
                                                        <img src="assets/icons/morning.svg" alt="" />
                                                            <span>6 – 8 AM<br />MORNING</span>
                                                        </div>
                                                    <div className="syrup-quantity">1 Pill</div>
                                                </div>
                                                <div className="midday">
                                                    <div className="time"><img src="assets/icons/midday.svg" alt="" /><span>11 – 1 PM<br/>MID DAY</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="evening">
                                                    <div className="time"><img src="assets/icons/evening.svg" alt="" /><span>4 – 6 PM<br/>EVENING</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="bedtime">
                                                    <div className="time"><img src="assets/icons/bedtime.svg" alt="" /><span>9 – 11 PM<br/>BEDTIME</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                            </div>
                                            <div className="block-head instructions">
                                                <span className="dosage">To be taken after food</span>
                                            </div>
                                            <div className="message">
                                                <ul>
                                                    <li>It is an Antibiotic</li>
                                                    <li>How it acts – It has anti-bacterial action which helps in preventing secondary bacterial infection. It also exhibits anti-inflammatory, anti-viral activity and immune modulatory benefits.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <div className="block-head">
                                            <h5>IVERMECTOL 12 mg <span style={{textTransform: 'uppercase'}}> TABLET</span></h5>
                                            <span className="generic-name">Ivermectin 12 mg</span><span className="dosage">Take 1 pill, Once daily for 5 days</span>
                                        </div>
                                        <div className="dosage-per-day">
                                            <div className="timings">
                                                <div className="morning">
                                                    <div className="time">
                                                        <img src="assets/icons/morning.svg" alt="" />
                                                            <span>6 – 8 AM<br />MORNING</span>
                                                        </div>
                                                    <div className="syrup-quantity">1 Pill</div>
                                                </div>
                                                <div className="midday">
                                                    <div className="time"><img src="assets/icons/midday.svg" alt="" /><span>11 – 1 PM<br/>MID DAY</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="evening">
                                                    <div className="time"><img src="assets/icons/evening.svg" alt="" /><span>4 – 6 PM<br/>EVENING</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="bedtime">
                                                    <div className="time"><img src="assets/icons/bedtime.svg" alt="" /><span>9 – 11 PM<br/>BEDTIME</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                            </div>
                                            <div className="block-head instructions">
                                                <span className="dosage">To be taken on empty stomach (1 hour before or 2 hours after food)</span>
                                            </div>
                                            <div className="message">
                                                <ul>
                                                    <li>It is used to treat infections caused by parasites</li>
                                                    <li>How it acts- It could prevent the virus from  suppressing our cells’ natural antiviral responses. it’s possible the drug prevents the “spike” protein on the surface of the virus from binding to the receptors that allow it to enter our cells. It has anti- inflammatory action.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <div className="block-head">
                                            <h5>FABIFLU 800 mg <span style={{textTransform: 'uppercase'}}> TABLET</span></h5>
                                            <span className="generic-name">Favipiravir 800 mg</span><span className="dosage">Take 2 pills, twice daily on day 1 and 1 pill, twice daily on day 2 to day 14</span>
                                        </div>
                                        <div className="dosage-per-day">
                                            <div className="timings">
                                                <div className="morning">
                                                    <div className="time">
                                                        <img src="assets/icons/morning.svg" alt="" />
                                                            <span>6 – 8 AM<br />MORNING</span>
                                                        </div>
                                                    <div className="syrup-quantity">2 Pills</div>
                                                </div>
                                                <div className="midday">
                                                    <div className="time"><img src="assets/icons/midday.svg" alt="" /><span>11 – 1 PM<br/>MID DAY</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="evening">
                                                    <div className="time"><img src="assets/icons/evening.svg" alt="" /><span>4 – 6 PM<br/>EVENING</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="bedtime">
                                                    <div className="time"><img src="assets/icons/bedtime.svg" alt="" /><span>9 – 11 PM<br/>BEDTIME</span></div>
                                                    <div className="syrup-quantity">2 Pills</div>
                                                </div>
                                            </div>
                                            <div className="block-head instructions">
                                                <span className="dosage">To be taken after food</span>
                                            </div>
                                            <div className="message">
                                                <ul>
                                                    <li>It is a broad spectrum anti viral drug effective against influenza</li>
                                                    <li>How it acts- It reduces viral replication / viral load by inhibits the activity of RNA dependent RNA polymerase (RdRp) of RNA viruses.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ribbon">
                                        <p>Steroids used in Covid</p>
                                    </div>
                                    <div className="content-box">
                                        <div className="block-head">
                                            <h5>BUDECORT 400 <span style={{textTransform: 'uppercase'}}> ROTACAP</span></h5>
                                            <span className="generic-name">Budesonide</span><span className="dosage">Inhale 2 rotacaps (800), twice daily using rotahaler for 7 days</span>
                                        </div>
                                        <div className="dosage-per-day">
                                            <div className="timings">
                                                <div className="morning">
                                                    <div className="time">
                                                        <img src="assets/icons/morning.svg" alt="" />
                                                            <span>6 – 8 AM<br />MORNING</span>
                                                        </div>
                                                    <div className="syrup-quantity">2 Rotacaps</div>
                                                </div>
                                                <div className="midday">
                                                    <div className="time"><img src="assets/icons/midday.svg" alt="" /><span>11 – 1 PM<br/>MID DAY</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="evening">
                                                    <div className="time"><img src="assets/icons/evening.svg" alt="" /><span>4 – 6 PM<br/>EVENING</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="bedtime">
                                                    <div className="time"><img src="assets/icons/bedtime.svg" alt="" /><span>9 – 11 PM<br/>BEDTIME</span></div>
                                                    <div className="syrup-quantity">2 Rotacaps</div>
                                                </div>
                                            </div>
                                            <div className="block-head instructions">
                                                <span className="dosage">No relation to food</span>
                                            </div>
                                            <div className="message">
                                                <ul>
                                                    <li>Its Inhaled steroid</li>
                                                    <li>How it acts- It reduces inflammation in lung</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <div className="block-head">
                                            <h5>DEXONA 6 mg <span style={{textTransform: 'uppercase'}}> TABLET</span></h5>
                                            <span className="generic-name">Dexamethasone</span><span className="dosage">Take 1 pill, once daily for 5 days</span>
                                        </div>
                                        <div className="dosage-per-day">
                                            <div className="timings">
                                                <div className="morning">
                                                    <div className="time">
                                                        <img src="assets/icons/morning.svg" alt="" />
                                                            <span>6 – 8 AM<br />MORNING</span>
                                                        </div>
                                                    <div className="syrup-quantity">1 Pill</div>
                                                </div>
                                                <div className="midday">
                                                    <div className="time"><img src="assets/icons/midday.svg" alt="" /><span>11 – 1 PM<br/>MID DAY</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="evening">
                                                    <div className="time"><img src="assets/icons/evening.svg" alt="" /><span>4 – 6 PM<br/>EVENING</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="bedtime">
                                                    <div className="time"><img src="assets/icons/bedtime.svg" alt="" /><span>9 – 11 PM<br/>BEDTIME</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                            </div>
                                            <div className="block-head instructions">
                                                <span className="dosage">To be taken after food</span>
                                            </div>
                                            <div className="message">
                                                <ul>
                                                    <li>It is a steroid</li>
                                                    <li>How it acts- In some COVID patients, the body  mounts an unrestrained inflammatory response to fight the infection, this can damage the body’s own cells and tissues. Dexamethasone helps to dampen the body’s immune response when it becomes too aggressive.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ribbon">
                                        <p>Medicines for symptomatic relief in COVID</p>
                                    </div>
                                    <div className="content-box">
                                        <div className="block-head">
                                            <h5>ALLEGRA 180 mg <span style={{textTransform: 'uppercase'}}> TABLET</span></h5>
                                            <span className="generic-name">Fexofenadine 180 mg</span><span className="dosage">Take 1 pill, once daily, if required for sneezing / running nose</span>
                                        </div>
                                        <div className="dosage-per-day">
                                            <div className="timings">
                                                <div className="morning">
                                                    <div className="time">
                                                        <img src="assets/icons/morning.svg" alt="" />
                                                            <span>6 – 8 AM<br />MORNING</span>
                                                        </div>
                                                    <div className="syrup-quantity">1 Pill</div>
                                                </div>
                                                <div className="midday">
                                                    <div className="time"><img src="assets/icons/midday.svg" alt="" /><span>11 – 1 PM<br/>MID DAY</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="evening">
                                                    <div className="time"><img src="assets/icons/evening.svg" alt="" /><span>4 – 6 PM<br/>EVENING</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="bedtime">
                                                    <div className="time"><img src="assets/icons/bedtime.svg" alt="" /><span>9 – 11 PM<br/>BEDTIME</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                            </div>
                                            <div className="block-head instructions">
                                                <span className="dosage">To be taken on empty stomach (1 hour before or 2 hours after food)</span>
                                            </div>
                                            <div className="message">
                                                <ul>
                                                    <li>It’s a anti-histamine</li>
                                                    <li>How it acts – It decreases your body's reaction to allergens and therefore helps to reduce the troublesome symptoms associated with allergy</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <div className="block-head">
                                            <h5>DOLO 650 mg <span style={{textTransform: 'uppercase'}}> TABLET</span></h5>
                                            <span className="generic-name">Paracetamol 650 mg</span><span className="dosage">To take 1 pill every 6 hourly, if required for fever / body pains/ headache</span>
                                        </div>
                                        <div className="dosage-per-day">
                                            <div className="timings">
                                                <div className="morning">
                                                    <div className="time">
                                                        <img src="assets/icons/morning.svg" alt="" />
                                                            <span>6 – 8 AM<br />MORNING</span>
                                                        </div>
                                                    <div className="syrup-quantity">1 Pill</div>
                                                </div>
                                                <div className="midday">
                                                    <div className="time"><img src="assets/icons/midday.svg" alt="" /><span>11 – 1 PM<br/>MID DAY</span></div>
                                                    <div className="syrup-quantity">1 Pill</div>
                                                </div>
                                                <div className="evening">
                                                    <div className="time"><img src="assets/icons/evening.svg" alt="" /><span>4 – 6 PM<br/>EVENING</span></div>
                                                    <div className="syrup-quantity">1 Pill</div>
                                                </div>
                                                <div className="bedtime">
                                                    <div className="time"><img src="assets/icons/bedtime.svg" alt="" /><span>9 – 11 PM<br/>BEDTIME</span></div>
                                                    <div className="syrup-quantity">1 Pill</div>
                                                </div>
                                            </div>
                                            <div className="block-head instructions">
                                                <span className="dosage">No relation to food</span>
                                            </div>
                                            <div className="message">
                                                <ul>
                                                    <li>How it acts- It relieves pain by raising the  body’s pain threshold, and it reduces fever by its action on the temperature-regulating centre of the brain</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <div className="block-head">
                                            <h5>ASCORIL D + <span style={{textTransform: 'uppercase'}}> SYRUP</span></h5>
                                            <span className="generic-name">Dextromethorphan</span><span className="dosage">Take 10 ml / 3 times a day if required for dry cough & cough with sputum</span>
                                        </div>
                                        <div className="dosage-per-day">
                                            <div className="timings">
                                                <div className="morning">
                                                    <div className="time">
                                                        <img src="assets/icons/morning.svg" alt="" />
                                                            <span>6 – 8 AM<br />MORNING</span>
                                                        </div>
                                                    <div className="syrup-quantity">10 ml</div>
                                                </div>
                                                <div className="midday">
                                                    <div className="time"><img src="assets/icons/midday.svg" alt="" /><span>11 – 1 PM<br/>MID DAY</span></div>
                                                    <div className="syrup-quantity">-</div>
                                                </div>
                                                <div className="evening">
                                                    <div className="time"><img src="assets/icons/evening.svg" alt="" /><span>4 – 6 PM<br/>EVENING</span></div>
                                                    <div className="syrup-quantity">10 ml</div>
                                                </div>
                                                <div className="bedtime">
                                                    <div className="time"><img src="assets/icons/bedtime.svg" alt="" /><span>9 – 11 PM<br/>BEDTIME</span></div>
                                                    <div className="syrup-quantity">10 ml</div>
                                                </div>
                                            </div>
                                            <div className="block-head instructions">
                                                <span className="dosage">No relation to food</span>
                                            </div>
                                            <div className="message">
                                                <ul>
                                                    <li>How it acts- It temporarily blocks communication between your brain and the nerves which provoke the coughing</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-12">
                                <div className="privacy">
                                    <p>For informational purposes only. Consult your local medical authority for health advice</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="treatments-footer">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <h3>You can self-treat symptoms of COVID @ Soctor.com.</h3>
                                <h3>Its Safe. Its easy. Its free. Try now</h3>
                                <button onClick={this.handleVaccinationBtn} type="button" className="btn btn-pink mt-3">Generate my self-medication advice now</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="treatments-footer-white">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <img className="footer-img" src="assets/covid/covid-treatments-footer.jpeg" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetHealthCategory: (id, type) => {
          dispatch(setHealthCategory(id, type));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CovidTreatments);
