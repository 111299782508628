import { fromJS } from 'immutable';
import { actionTypes as at } from '../actions/consultation/constants'

const initialState = fromJS({
    selectedHealthCategory: '',
    selectedPatientType: '',
    selectedSubHealthCategory: '',
    healthCategoriesLoading: false,
    healthCategories: [],
    subHealthCategoriesLoading: false,
    subHealthCategories: [],
    symptomsLoading: false,
    symptoms: [],
    diseasesLoading: false,
    diseases: [],
    prescription: {},
    emailSent: false,
    downloadStatus: false,
    prescriptionLoaded: false,
    consultationData: {
        healthConditionId: '',
        subHealthConditionId: '',
        symptomsSelected: [],
        medicalHistory: [],
        age: null,
        weigth: null
    }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case at.FETCH_HEALTH_CATEGORIES:
      return state
        .set('healthCategoriesLoading', true)

    case at.FETCH_HEALTH_CATEGORIES_SUCCESS:
      return state
        .set('healthCategories', action.payload)
        .set('healthCategoriesLoading', false)

    case at.FETCH_HEALTH_CATEGORIES_ERROR:
        return state
        .set('healthCategoriesLoading', false)

    case at.SET_HEALTH_CATEGORY:
        return state
        .set('selectedHealthCategory', action.payload.id)
        .set('selectedPatientType', action.payload.type)

    case at.SET_SUB_HEALTH_CATEGORY:
        return state
        .set('selectedSubHealthCategory', action.payload)

    case at.FETCH_SUB_HEALTH_CATEGORIES:
        return state
        .set('subHealthCategoriesLoading', true)

    case at.FETCH_SUB_HEALTH_CATEGORIES_SUCCESS:
        return state
        .set('subHealthCategories', action.payload)
        .set('subHealthCategoriesLoading', false)

    case at.FETCH_SUB_HEALTH_CATEGORIES_ERROR:
        return state
        .set('subHealthCategoriesLoading', false)

    case at.FETCH_DISEASES:
        return state
        .set('diseasesLoading', true)

    case at.FETCH_DISEASES_SUCCESS:
        return state
        .set('diseases', action.payload)
        .set('diseasesLoading', false)

    case at.FETCH_DISEASES_ERROR:
        return state
        .set('diseasesLoading', false)

    case at.FETCH_SYMPTOMS:
        return state
        .set('symptomsLoading', true)

    case at.FETCH_SYMPTOMS_SUCCESS:
        return state
        .set('symptoms', action.payload)
        .set('symptomsLoading', false)

    case at.FETCH_SYMPTOMS_ERROR:
        return state
        .set('symptomsLoading', false)

    case at.FETCH_PRESCRIPTION_SUCCESS:
        return state
        .set('prescription', action.payload)
        .set('prescriptionLoaded', true)

    case at.CLEAR_PRESCRIPTION:
        return state
        .set('prescription', {})
        .set('emailSent', false)
        .set('selectedPatientType', '')
        .set('consultationData', {
            healthConditionId: '',
            subHealthConditionId: '',
            symptomsSelected: [],
            medicalHistory: [],
            age: null,
            weigth: null
        })

    case at.EMAIL_PRESCRIPTION_SUCCESS:
        return state
        .set('emailSent', true)
    case at.DOWNLOAD_PRESCRIPTION_SUCCESS:
        return state
        .set('downloadStatus', action.payload)

    case at.UPDATE_CUSTOMER_CONSULTATION_DATA: 
        return state
        .set('consultationData', action.payload)

    case at.RESET_PRESCRIPTION:
        return state
        .set('selectedHealthCategory', '')
        .set('selectedPatientType', '')
        .set('selectedSubHealthCategory', false)
        .set('symptoms', [])
        .set('diseases', [])
        .set('subHealthCategories', [])
        .set('healthCategories', [])
        .set('prescriptionLoaded', false)
    
    default:
      return state;
  }
};
