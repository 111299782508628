import * as React from 'react';


const connect = require('react-redux').connect;

class PrivacyPolicy extends React.Component {
    render() {
        return (
            <div className="container page">
                <div className="row">
                    <div className="col-12">
                        <h3 className="title">PRIVACY POLICY (APP)</h3>
                        <p>Posted as of 14 November 2020</p>
                        <p>Last updated as of 14 November 2020</p>
                        <p><strong><em>Welcome to Soctor&rsquo;s Privacy Policy</em></strong></p>
                        <p>This Application is created and operated by <strong>Soctor.</strong></p>
                        <p>This legal agreement is an electronic record in terms of Indian Information Technology Act, 2000 and rules there under as applicable and the amended provisions about electronic records in various statutes as amended by the Indian Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
                        <p>This legal document is published in accordance with the provisions of Rule 3 (1) of the Indian Information Technology (Intermediaries guidelines) Rules, 2011 and Rule 4 of the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 of Information Technology Act, 2000 amended through Information Technology Amendment Act, 2008 that require publishing the Terms of Services and practices for access or usage of <strong>www.soctor.com</strong> (<strong><em>&ldquo;website/platform&rdquo;</em></strong>)</p>
                        <p>The creator of this Privacy Policy ensures a steady commitment to Your privacy with regard to the protection of your invaluable information that you may share across on this platform. This privacy policy contains information about the Application <strong><em>&ldquo;Soctor&rdquo;</em></strong>(hereinafter referred to as the &ldquo;<strong>Platform</strong>&rdquo;)</p>
                        <p>To provide You with Our uninterrupted use of the Application, We may collect and, in some circumstances, disclose information about you with your permission. To ensure better protection of Your privacy, We provide this notice explaining Our information collection and disclosure policies, and the choices You make about the way Your information is collected and used.</p>
                        <p><strong>This Privacy Policy shall be in compliance with the General Data Protection Regulation (GDPR) in effect from May 25, 2018, and any and all provisions that may read to the contrary shall be deemed to be void and unenforceable as of that date. If you do not agree with the terms and conditions of our Privacy Policy, including in relation to the manner of collection or use of your information, please do not use or access the Site. If you have any questions or concerns regarding this Privacy Policy, you should contact our Customer Support Desk at contactus@soctor.com.</strong></p>
                        <p>ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING ACCORDED TO THEM UNDER THIS AGREEMENT. FURTHER, ALL HEADING USED&nbsp;</p>
                        <p>HEREIN ARE ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS PROVISIONS OF THE AGREEMENT IN ANY MANNER. NEITHER THE USER NOR THE CREATORS OF THIS PRIVACY POLICY MAY USE THE HEADING TO INTERPRET THE PROVISIONS CONTAINED WITHIN IT IN ANY MANNER.</p>
                        <ul>
                        <li><strong><strong>INFORMATION WE COLLECT</strong></strong></li>
                        </ul>
                        <p>We are committed to respecting Your online privacy. We further recognize Your need for appropriate protection and management of any Personal Information You share with us. We may collect the following information:&nbsp;</p>
                        <ol>
                        <li>Personal data such as, but not limited to, Name, Age, Sex, Height, Weight, Symptoms, Allergies, Pre-existing diseases, Email, Mobile No, Address.</li>
                        <li>Information collected through the permission sought through the mobile applications which would mainly include Phone Book, SMS permissions etc</li>
                        <li>Tracking Information such as, but not limited to the IP address of your device and Device ID when connected to the Internet. This information may include the URL that you just came from (whether this URL is on the Platform or not), which URL you next go to (whether this URL is on the Platform or not), your computer or device browser information, and other information associated with your interaction with the Platform;</li>
                        <li>Details of Platform usage for analytics.</li>
                        </ol>
                        <p>This privacy policy also applies to data we collect from users who are not registered as members of this Platform, including, but not limited to, browsing behaviour, pages viewed etc. We also collect and store personal information provided by You from time to time on the Platform. We only collect and use such information from you that we consider necessary for achieving a seamless, efficient and safe experience, customized to your needs including:&nbsp;</p>
                        <ol>
                        <li>To enable the provision of services opted for by you;</li>
                        <li>To enable the viewing of content in your interest;</li>
                        <li>To communicate the necessary account and service related information from time to time;</li>
                        <li>To allow you to receive quality customer care services and data Collection;</li>
                        <li>To comply with applicable laws, rules and regulations;</li>
                        </ol>
                        <p>Where any service requested by You involves a third party, such information as is reasonably necessary by the Company to carry out Your service request may be shared with such third party. We also do use your contact information to send you offers based on your interests and prior activity and also to view the content preferred by you. The Company may also use contact information internally to direct its efforts for service improvement but shall immediately delete all such information upon withdrawal of your consent for the same through the &lsquo;<strong>unsubscribe</strong>&rsquo; button or through an email to be sent to<strong><em> contactus@soctor.com</em></strong>.</p>
                        <p>To the extent possible, we provide You with the option of not divulging any specific information that you wish for us not to collect, store or use. You may also choose not to use a particular service or feature on the Platform and opt-out of any non-essential communications from the platform.&nbsp;</p>
                        <p>Further, transacting over the internet has inherent risks which can only be avoided by you following security practices yourself, such as not revealing account/login related information to any other person and informing our customer care team about any suspicious activity or where your account has/may have been compromised.</p>
                        <ul>
                        <li><strong><strong>OUR USE OF YOUR INFORMATION</strong></strong></li>
                        </ul>
                        <p>The information provided by you shall be used to provide and improve the service for you and all users.</p>
                        <ol>
                        <li>To provide you with services on your request.</li>
                        <li>For maintaining an internal record.</li>
                        <li>For enhancing the Services provided.&nbsp;</li>
                        </ol>
                        <p>For more details about the nature of such communications, please refer to our Terms of Service. Further, your data and Sensitive Personal data may be collected and stored by Us for internal record.&nbsp;</p>
                        <p>We use Your tracking information such as IP addresses, and or Device ID to help identify you and to gather broad demographic information and make further services available to you.&nbsp;</p>
                        <p>We will not sell, license or trade Your personal information. We will not share your personal information with others unless they are acting under our instructions or we are required to do so by law.&nbsp;</p>
                        <p>Information collected via Our server logs includes users' IP addresses and the pages visited; this will be used to manage the web system and troubleshoot problems. We also use third-party analytics, tracking, optimization and targeting tools to understand how users engage with our Platform so that we can improve it and cater personalized content/ads according to their preferences.</p>
                        <ul>
                        <li><strong><strong>HOW INFORMATION IS COLLECTED</strong></strong></li>
                        </ul>
                        <p>Before or at the time of collecting personal information, we will identify the purposes for which information is being collected. If the same is not identified to you, you have the right to request the Company to elucidate the purpose of collection of said personal information, pending the fulfilment of which you shall not be mandated to disclose any information whatsoever.&nbsp;</p>
                        <p>We will collect and use your personal information solely to fulfil those purposes specified by us, within the scope of the consent of the individual concerned or as required by law. We will only retain personal information as long as necessary for the fulfilment of those purposes. We will collect personal information by lawful and fair means and with the knowledge and consent of the individual concerned.&nbsp;</p>
                        <p>Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.</p>
                        <ul>
                        <li><strong><strong>EXTERNAL LINKS ON THE PLATFORM</strong></strong></li>
                        </ul>
                        <p>The Platform may include advertisements, hyperlinks to other Websites or resources. We have no control over any other website or resources or contents available on these other websites, which are provided by companies or persons other than Us. You acknowledge and agree that we are not responsible for the availability of any such external sites or resources, and do not endorse any advertising, services/services or other materials on or available from such platform or resources. You acknowledge and agree that We are not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, services or other materials on, or available from, such Websites. These external third party websites and resource providers may have their privacy policies governing the collection, storage, retention and disclosure of Your Personal Information that You may be subject to. We recommend that you enter the Website and review their privacy policy.</p>
                        <ul>
                        <li><strong><strong>YOUR RIGHTS</strong></strong></li>
                        </ul>
                        <p>Unless subject to an exemption, you have the following rights concerning your data:&nbsp;</p>
                        <ol>
                        <ol>
                        <li>The right to request a copy of your data which we hold about you;&nbsp;</li>
                        <li>The right to request for any correction to any personal data if it is found to be inaccurate or out of date;</li>
                        <li>The right to withdraw Your consent to the processing at any time;</li>
                        <li>The right to object to the processing of personal data;</li>
                        <li>The right to complain about a supervisory authority.&nbsp;</li>
                        <li>The right to obtain information as to whether personal data are transferred to a third country or an international organization.</li>
                        </ol>
                        </ol>
                        <p>Where you hold an account with any of our services, you are entitled to a copy of all personal data which we hold concerning you. You are also entitled to request that we restrict how we use your data in your account when you log in.</p>
                        <ul>
                        <li><strong><strong>CONFIDENTIALITY</strong></strong></li>
                        </ul>
                        <p>You further acknowledge that the Platform may contain information which is designated confidential by us and that you shall not disclose such information without our prior written consent. Your information is regarded as confidential and therefore will not be divulged to any third party, unless if legally required to do so to the appropriate authorities. We will not sell, share, or rent your personal information to any third party or use your e-mail address for unsolicited mail. Any emails sent by us will only be in connection with the provision of agreed services, and you retain sole discretion to seek for discontinuation of such communications at any point of time.&nbsp;</p>
                        <ul>
                        <li><strong><strong>OTHER INFORMATION COLLECTORS&nbsp;</strong></strong></li>
                        </ul>
                        <p>Except as otherwise expressly included in this Privacy Policy, this document only addresses the use and disclosure of information we collect from you. To the extent that you disclose your information to other parties, whether they are on our Platform or other sites throughout the Internet, different rules may apply to their use or disclosure of the information you disclose to them. To the extent that we use third party advertisers, they adhere to their privacy policies. Since we do not control the privacy policies of the third parties, you are subject to ask questions before you disclose your personal information to others.&nbsp;</p>
                        <ul>
                        <li><strong><strong>OUR DISCLOSURE OF YOUR INFORMATION&nbsp;</strong></strong></li>
                        </ul>
                        <p>We may host surveys for survey creators for our platform who are the owners and users of your survey responses. We do not own or sell your responses. Anything you expressly disclose in your responses will be disclosed to survey creators. Please contact the survey creator directly to better understand how they might share your survey responses.</p>
                        <p>Information collected will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005, any rules made thereunder or any other law for the time being in force.</p>
                        <p>Due to the existing regulatory environment, we cannot ensure that all of your private communications and other personally identifiable information will never be disclosed in ways not otherwise described in this Privacy Policy. By way of example (without limiting and foregoing), we may be forced to disclose information to the government, law enforcement agencies or third parties. Therefore, although we use industry-standard practices to protect your privacy, we do not promise, and you should not expect, that your personally identifiable information or private communications would always remain private. We do however assure you that any disclosure of your personally identifiable information shall be personally intimated to you through an email sent to your provided email address.</p>
                        <p>As a matter of policy, we do not sell or rent any personally identifiable information about you to any third party. However, the following describes some of the ways that your personally identifiable information may be disclosed:&nbsp;</p>
                        <ol>
                        <ol>
                        <ol>
                        <li>External Service Providers: There may be several services offered by external service providers that help you use our Platform. If you choose to use these optional services, and in the course of doing so, disclose information to the external service providers, and/or permit them to collect information about you, then their use of your information is governed by their privacy policy.</li>
                        <li>Law and Order: We cooperate with law enforcement inquiries, as well as other third parties to enforce laws, such as intellectual property rights, fraud and other rights. We can (and you authorize us to) disclose any information about you to law enforcement and other government officials as we, in our sole discretion, believe necessary or appropriate, in connection with an investigation of fraud, intellectual property infringements, or other activity that is illegal or may expose us or you to legal liability.&nbsp;</li>
                        </ol>
                        </ol>
                        </ol>
                        <ul>
                        <li><strong><strong>ACCESSING, REVIEWING AND CHANGING YOUR PROFILE</strong></strong></li>
                        </ul>
                        <p>Following registration, you can review and change the information you submitted at the stage of registration, except Email ID and mobile number. An option for facilitating such change shall be present on the Platform and such change shall be facilitated by the User. If you change any information, we may or may not keep track of your old information. We will not retain in our files information you have requested to remove for certain circumstances, such as to resolve disputes, troubleshoot problems and enforce our terms and conditions. Such prior information shall be completely removed from our databases, including stored &lsquo;back up&rsquo; systems. If you believe that any information, we are holding on you is incorrect or incomplete, or to remove your profile so that others cannot view it, the User needs to remediate, and promptly correct any such incorrect information.</p>
                        <ul>
                        <li><strong><strong>CONTROL OF YOUR PASSWORD</strong></strong></li>
                        </ul>
                        <p>You are entirely responsible for maintaining the confidentiality of your password. You must protect it against unauthorized access of your account and information by choosing your password carefully and keeping your password and computer secure by signing out after using our services.&nbsp;</p>
                        <p>You agree not to use the account, username, email address or password of another Member at any time or to disclose your password to any third party. You are responsible for all actions taken with your login information and password, including fees. If you lose control of your password, you may lose substantial control over your personally identifiable information and may be subject to legally binding actions taken on your behalf. Therefore, if your password has been compromised for any reason, you should immediately change your password. You agree to notify us immediately if you suspect any consistent unauthorized use of your account or access to your password even after changing it.</p>
                        <ul>
                        <li><strong><strong>SECURITY</strong></strong></li>
                        </ul>
                        <p>We treat data as an asset that must be protected against loss and unauthorized access. We employ many different security techniques to protect such data from unauthorized access by members inside and outside the Company. We follow generally accepted industry standards to protect the Personal Information submitted to us and information that we have accessed.</p>
                        <p>However, as effective as encryption technology is, no security system is impenetrable. Our Company cannot guarantee the security of our database, nor can we guarantee that information you provide won&rsquo;t be intercepted while being transmitted to the Company over the Internet.&nbsp;</p>
                        <ul>
                        <li><strong><strong>SEVERABILITY&nbsp;</strong></strong></li>
                        </ul>
                        <p>Each paragraph of this Privacy Policy shall be and remain separate from and independent of and severable from all and any other paragraphs herein except where otherwise expressly indicated or indicated by the context of the agreement. The decision or declaration that one or more of the paragraphs are null and void shall not affect the remaining paragraphs of this privacy policy.</p>
                        <ul>
                        <li><strong><strong>AMENDMENT&nbsp;</strong></strong></li>
                        </ul>
                        <p>Our Privacy Policy may change from time to time. The most current version of the policy will govern our use of your information and will always be at the Platform. Any amendments to this Policy shall be deemed as accepted by the User on their continued use of the Platform.&nbsp;</p>
                        <ul>
                        <li><strong><strong>AUTOMATED DECISION MAKING&nbsp;</strong></strong></li>
                        </ul>
                        <p>As a responsible Company, we do not use automatic decision-making or profiling.</p>
                        <ul>
                        <li><strong><strong>CONSENT WITHDRAWAL, DATA DOWNLOAD &amp; DATA REMOVAL REQUESTS</strong></strong></li>
                        </ul>
                        <p>To withdraw your consent, or to request the download or delete your data with us for any or all our services at any time, please email to <strong><em>contactus@soctor.com</em></strong><strong>.</strong></p>
                        <ul>
                        <li><strong><strong>CONTACT US&nbsp;</strong></strong></li>
                        </ul>
                        <p>If you have any questions or concerns regarding this privacy policy, you should contact us by sending an e-mail to please email to<strong><em>contactus@soctor.com.</em></strong></p>
                        <p>Information provided on the Platform may not be accurate and may be provided for promotional purposes of the business.</p>
                        <p>----------------------------------------------------------------------------------------------------------------------------------------------------</p>
                        <p><strong>PRIVACY POLICY (WEB)</strong></p>
                        <p>Posted as of 14 November 2020</p>
                        <p>Last updated as of 14 November 2020</p>
                        <p><strong><em>Welcome to Soctor&rsquo;s Privacy Policy</em></strong></p>
                        <p>This Website is created and operated by <strong>Soctor</strong></p>
                        <p>This legal agreement is an electronic record in terms of Indian Information Technology Act, 2000 and rules there under as applicable and the amended provisions about electronic records in various statutes as amended by the Indian Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
                        <p>This legal document is published in accordance with the provisions of Rule 3 (1) of the Indian Information Technology (Intermediaries guidelines) Rules, 2011 and Rule 4 of the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 of Information Technology Act, 2000 amended through Information Technology Amendment Act, 2008 that require publishing the Terms of Services and practices for access or usage of <strong>www.soctor.com</strong> (<strong><em>&ldquo;website/platform&rdquo;</em></strong>)</p>
                        <p>The creator of this Privacy Policy ensures a steady commitment to Your privacy with regard to the protection of your invaluable information that you may share across on this platform. This privacy policy contains information about the Website <strong><em>&ldquo;www.soctor.com&rdquo;</em></strong>(hereinafter referred to as the &ldquo;<strong>Platform</strong>&rdquo;)</p>
                        <p>To provide You with Our uninterrupted use of the Application, We may collect and, in some circumstances, disclose information about you with your permission. To ensure better protection of Your privacy, We provide this notice explaining Our information collection and disclosure policies, and the choices You make about the way Your information is collected and used.</p>
                        <p><strong>This Privacy Policy shall be in compliance with the General Data Protection Regulation (GDPR) in effect from May 25, 2018, and any and all provisions that may read to the contrary shall be deemed to be void and unenforceable as of that date. If you do not agree with the terms and conditions of our Privacy Policy, including in relation to the manner of collection or use of your information, please do not use or access the Site. If you have any questions or concerns regarding this Privacy Policy, you should contact our Customer Support Desk at </strong><strong><em>contactus@soctor.com</em></strong><strong>.</strong></p>
                        <p>ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING ACCORDED TO THEM UNDER THIS AGREEMENT. FURTHER, ALL HEADING USED&nbsp;</p>
                        <p>HEREIN ARE ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS PROVISIONS OF THE AGREEMENT IN ANY MANNER. NEITHER THE USER NOR THE CREATORS OF THIS PRIVACY POLICY MAY USE THE HEADING TO INTERPRET THE PROVISIONS CONTAINED WITHIN IT IN ANY MANNER.</p>
                        <ul>
                        <li><strong><strong>INFORMATION WE COLLECT</strong></strong></li>
                        </ul>
                        <p>We are committed to respecting Your online privacy. We further recognize Your need for appropriate protection and management of any Personal Information You share with us. We may collect the following information:&nbsp;</p>
                        <ol>
                        <li>Tracking Information such as, but not limited to the IP address of your device and Device ID when connected to the Internet. This information may include the URL that you just came from (whether this URL is on the Platform or not), which URL you next go to (whether this URL is on the Platform or not), your computer or device browser information, and other information associated with your interaction with the Platform;</li>
                        <li>Details of Platform usage for analytics.</li>
                        </ol>
                        <p>This privacy policy also applies to data we collect from users who are not registered as members of this Platform, including, but not limited to, browsing behaviour, pages viewed etc. We also collect and store personal information provided by You from time to time on the Platform. We only collect and use such information from you that we consider necessary for achieving a seamless, efficient and safe experience, customized to your needs including:&nbsp;</p>
                        <ol>
                        <li>To enable the provision of services opted for by you;</li>
                        <li>To enable the viewing of content in your interest;</li>
                        <li>To communicate the necessary account and service related information from time to time;</li>
                        <li>To allow you to receive quality customer care services and data Collection;</li>
                        <li>To comply with applicable laws, rules and regulations;</li>
                        </ol>
                        <p>Where any service requested by You involves a third party, such information as is reasonably necessary by the Company to carry out Your service request may be shared with such third party. We also do use your contact information to send you offers based on your interests and prior activity and also to view the content preferred by you. The Company may also use contact information internally to direct its efforts for service improvement but shall immediately delete all such information upon withdrawal of your consent for the same through the &lsquo;<strong>unsubscribe</strong>&rsquo; button or through an email to be sent to<strong><em> contactus@soctor.com</em></strong>.</p>
                        <p>To the extent possible, we provide You with the option of not divulging any specific information that you wish for us not to collect, store or use. You may also choose not to use a particular service or feature on the Platform and opt-out of any non-essential communications from the platform.&nbsp;</p>
                        <p>Further, transacting over the internet has inherent risks which can only be avoided by you following security practices yourself, such as not revealing account/login related information to any other person and informing our customer care team about any suspicious activity or where your account has/may have been compromised.</p>
                        <ul>
                        <li><strong><strong>OUR USE OF YOUR INFORMATION</strong></strong></li>
                        </ul>
                        <p>The information that we receive from you from tracking your usage of the platform, it shall be used to provide and improve the service for you and all users.</p>
                        <ol>
                        <li>To provide you with services on your request.</li>
                        <li>For maintaining an internal record.</li>
                        <li>For enhancing the Services provided.&nbsp;</li>
                        </ol>
                        <p>For more details about the nature of such communications, please refer to our Terms of Service. Further, your data and Sensitive Personal data may be collected and stored by Us for internal record.&nbsp;</p>
                        <p>We use Your tracking information such as IP addresses, and or Device ID to help identify you and to gather broad demographic information and make further services available to you.&nbsp;</p>
                        <p>We will not sell, license or trade Your personal information. We will not share your personal information with others unless they are acting under our instructions or we are required to do so by law.&nbsp;</p>
                        <p>Information collected via Our server logs includes users' IP addresses and the pages visited; this will be used to manage the web system and troubleshoot problems. We also use third-party analytics, tracking, optimization and targeting tools to understand how users engage with our Platform so that we can improve it and cater personalized content/ads according to their preferences.</p>
                        <ul>
                        <li><strong><strong>EXTERNAL LINKS ON THE PLATFORM</strong></strong></li>
                        </ul>
                        <p>The Platform may include advertisements, hyperlinks to other Websites or resources. We have no control over any other website or resources or contents available on these other websites, which are provided by companies or persons other than Us. You acknowledge and agree that we are not responsible for the availability of any such external sites or resources, and do not endorse any advertising, services/services or other materials on or available from such platform or resources. You acknowledge and agree that We are not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, services or other materials on, or available from, such Websites. These external third party websites and resource providers may have their privacy policies governing the collection, storage, retention and disclosure of Your Personal Information that You may be subject to. We recommend that you enter the Website and review their privacy policy.</p>
                        <ul>
                        <li><strong><strong>YOUR RIGHTS</strong></strong></li>
                        </ul>
                        <p>Unless subject to an exemption, you have the following rights concerning your data:&nbsp;</p>
                        <ol>
                        <ol>
                        <li>The right to request a copy of your data which we hold about you;&nbsp;</li>
                        <li>The right to request for any correction to any personal data if it is found to be inaccurate or out of date;</li>
                        <li>The right to withdraw Your consent to the processing at any time;</li>
                        <li>The right to object to the processing of personal data;</li>
                        <li>The right to complain about a supervisory authority.&nbsp;</li>
                        <li>The right to obtain information as to whether personal data are transferred to a third country or an international organization.</li>
                        </ol>
                        </ol>
                        <p>Where you hold an account with any of our services, you are entitled to a copy of all personal data which we hold concerning you. You are also entitled to request that we restrict how we use your data in your account when you log in.</p>
                        <ul>
                        <li><strong><strong>CONFIDENTIALITY</strong></strong></li>
                        </ul>
                        <p>You further acknowledge that the Platform may contain information which is designated confidential by us and that you shall not disclose such information without our prior written consent. Your information is regarded as confidential and therefore will not be divulged to any third party, unless if legally required to do so to the appropriate authorities. We will not sell, share, or rent your personal information to any third party or use your e-mail address for unsolicited mail. Any emails sent by us will only be in connection with the provision of agreed services, and you retain sole discretion to seek for discontinuation of such communications at any point of time.&nbsp;</p>
                        <ul>
                        <li><strong><strong>OTHER INFORMATION COLLECTORS&nbsp;</strong></strong></li>
                        </ul>
                        <p>Except as otherwise expressly included in this Privacy Policy, this document only addresses the use and disclosure of information we collect from you. To the extent that you disclose your information to other parties, whether they are on our Platform or other sites throughout the Internet, different rules may apply to their use or disclosure of the information you disclose to them. To the extent that we use third party advertisers, they adhere to their privacy policies. Since we do not control the privacy policies of the third parties, you are subject to ask questions before you disclose your personal information to others.&nbsp;</p>
                        <ul>
                        <li><strong><strong>OUR DISCLOSURE OF YOUR INFORMATION&nbsp;</strong></strong></li>
                        </ul>
                        <p>We may host surveys for survey creators for our platform who are the owners and users of your survey responses. We do not own or sell your responses. Anything you expressly disclose in your responses will be disclosed to survey creators. Please contact the survey creator directly to better understand how they might share your survey responses.</p>
                        <p>Information collected will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005, any rules made thereunder or any other law for the time being in force.</p>
                        <p>Due to the existing regulatory environment, we cannot ensure that all of your private communications and other personally identifiable information will never be disclosed in ways not otherwise described in this Privacy Policy. By way of example (without limiting and foregoing), we may be forced to disclose information to the government, law enforcement agencies or third parties. Therefore, although we use industry-standard practices to protect your privacy, we do not promise, and you should not expect, that your personally identifiable information or private communications would always remain private. We do however assure you that any disclosure of your personally identifiable information shall be personally intimated to you through an email sent to your provided email address.</p>
                        <p>As a matter of policy, we do not sell or rent any personally identifiable information about you to any third party. However, the following describes some of the ways that your personally identifiable information may be disclosed:&nbsp;</p>
                        <ol>
                        <ol>
                        <ol>
                        <li>External Service Providers: There may be several services offered by external service providers that help you use our Platform. If you choose to use these optional services, and in the course of doing so, disclose information to the external service providers, and/or permit them to collect information about you, then their use of your information is governed by their privacy policy.</li>
                        <li>Law and Order: We cooperate with law enforcement inquiries, as well as other third parties to enforce laws, such as intellectual property rights, fraud and other rights. We can (and you authorize us to) disclose any information about you to law enforcement and other government officials as we, in our sole discretion, believe necessary or appropriate, in connection with an investigation of fraud, intellectual property infringements, or other activity that is illegal or may expose us or you to legal liability.&nbsp;</li>
                        </ol>
                        </ol>
                        </ol>
                        <ul>
                        <li><strong><strong>SECURITY</strong></strong></li>
                        </ul>
                        <p>We treat data as an asset that must be protected against loss and unauthorized access. We employ many different security techniques to protect such data from unauthorized access by members inside and outside the Company. We follow generally accepted industry standards to protect the Personal Information submitted to us and information that we have accessed.</p>
                        <p>However, as effective as encryption technology is, no security system is impenetrable. Our Company cannot guarantee the security of our database, nor can we guarantee that information you provide won&rsquo;t be intercepted while being transmitted to the Company over the Internet.&nbsp;</p>
                        <ul>
                        <li><strong><strong>SEVERABILITY&nbsp;</strong></strong></li>
                        </ul>
                        <p>Each paragraph of this Privacy Policy shall be and remain separate from and independent of and severable from all and any other paragraphs herein except where otherwise expressly indicated or indicated by the context of the agreement. The decision or declaration that one or more of the paragraphs are null and void shall not affect the remaining paragraphs of this privacy policy.</p>
                        <ul>
                        <li><strong><strong>AMENDMENT&nbsp;</strong></strong></li>
                        </ul>
                        <p>Our Privacy Policy may change from time to time. The most current version of the policy will govern our use of your information and will always be at the Platform. Any amendments to this Policy shall be deemed as accepted by the User on their continued use of the Platform.&nbsp;</p>
                        <ul>
                        <li><strong><strong>AUTOMATED DECISION MAKING&nbsp;</strong></strong></li>
                        </ul>
                        <p>As a responsible Company, we do not use automatic decision-making or profiling.</p>
                        <ul>
                        <li><strong><strong>CONSENT WITHDRAWAL, DATA DOWNLOAD &amp; DATA REMOVAL REQUESTS</strong></strong></li>
                        </ul>
                        <p>To withdraw your consent, or to request the download or delete your data with us for any or all our services at any time, please email to <strong><em>contactus@soctor.com</em></strong><strong>.</strong></p>
                        <ul>
                        <li><strong><strong>CONTACT US&nbsp;</strong></strong></li>
                        </ul>
                        <p>If you have any questions or concerns regarding this privacy policy, you should contact us by sending an e-mail to please email to<strong><em>contactus@soctor.com.</em></strong></p>
                        <p>Information provided on the Platform may not be accurate and may be provided for promotional purposes of the business.</p>
                        <p><br /><br /></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, null)(PrivacyPolicy);
