import * as React from 'react';
import { Link, useHistory} from 'react-router-dom'
import {updateHeaderMessageBox} from '../../actions/login/actions';

const connect = require('react-redux').connect;

class Header extends React.Component {
    static getDerivedStateFromProps(props) {
        return {
            isAuthorised: props.isAuthorised,
            user: props.user,
            showHeaderBox: props.showHeaderBox
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            isAuthorised : false,
            activeClass: 'top',
            user: {},
            menuWidth: 0,
            showHeaderBox: true
        }
        this.computeHeaderClass = this.computeHeaderClass.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleToggleMenu = this.handleToggleMenu.bind(this);
        this.handleCovidBtnClick = this.handleCovidBtnClick.bind(this);
        this.computeCovidLinkVisibility = this.computeCovidLinkVisibility.bind(this);
        this.handleHeaderBarClick = this.handleHeaderBarClick.bind(this);
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                menuWidth: 0
            })
        }
    }
    componentDidMount(){
      window.addEventListener('scroll', () => {
        let activeClass = 'floating';
        if(window.scrollY === 0){
            activeClass = 'top';
        }
        this.setState({ activeClass });
     });
    }
    computeHeaderClass(){
        if(this.props.location.pathname === '/' || this.props.location.pathname === '/covid-vaccination')
         {
             return this.state.activeClass + ' '+ 'fixed';
         } else {
             return 'relative'
         }
    }
    handleBackClick(e) { 
        e.preventDefault();
        const currLocation = this.props.location.pathname;
        if(currLocation === '/consultation') {
            this.props.history.push('/')
        } else if(currLocation.includes('subhealthcategory')) {
            this.props.history.push('/consultation')
        } else if(currLocation.includes('medicalhistory')) {
            this.props.history.goBack()
        }
    }
    handleToggleMenu(e) {
        e.preventDefault();
        this.setState({
            menuWidth: this.state.menuWidth === 250 ? 0 : 250
        })
    }
    handleHeaderBarClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.onUpdateHeaderMessageBox(false);
    }
    handleCovidBtnClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.history.push('/covidtreatments');
    }
    computeCovidLinkVisibility(){
        const currLocation = this.props.location.pathname;
        if(currLocation === '/') {
            return false
        } else return true
    }
    render() {
        const currLocation = this.props.location.pathname;
        return (
            <header className={this.computeHeaderClass()}>
                <div id="mySidenav" style={{ width: this.state.menuWidth+'px'}} className="sidenav">
                    <a className="closebtn" onClick={e => this.handleToggleMenu(e)}>&times;</a>
                    <Link to="/covidtreatments">Covid Treatments</Link>
                    <Link to="/covid-vaccination">Covid Vaccination</Link>
                    <Link to="/aboutus">About Us</Link>
                    <Link to="/contactus">Contact Us</Link>
                    {this.props.isAuthorised 
                    ?
                    <Link to="/profile">Profile</Link>
                    : 
                    <Link to="/login">Login</Link>
                    }
                </div>
                <div className="container">
                        {
                        (currLocation === '/consultation' || currLocation.includes('subhealthcategory') || currLocation.includes('medicalhistory')) 
                        ? 
                        <div className="row align-items-center">
                            <div className="col-2">
                                <a onClick={e => this.handleBackClick(e, 0)} type="button"  className="back-btn"><img src="/assets/icons/left-arrow.svg" /></a>  
                            </div>
                            <div className="col text-center">
                                <Link to={'/'}>
                                    <img src="/assets/logo.svg" alt="" />
                                </Link>
                            </div>
                            <div className="col-2 text-right my-profile">
                                <span className="menuButton" onClick={e => this.handleToggleMenu(e)}><img src="/assets/icons/menu.svg" /></span>
                            </div>
                        </div>
                        : 
                        <div className="row align-items-center">
                            <div className="col-auto">  
                                <Link to={'/'}>
                                    <img src="/assets/logo.svg" alt="" />
                                </Link>
                            </div>
                            <div className="col text-right my-profile">
                                <span className="menuButton" onClick={e => this.handleToggleMenu(e)}><img src="/assets/icons/menu.svg" /></span>
                            </div>
                        </div>
                        }
                </div>
            </header>
        );
    }
}

function mapStateToProps(state) {
    const loginState = state.get('loginReducer').toJS()
    return {
        isAuthorised: loginState.isAuthorised,
        user: loginState.user,
        showHeaderBox: loginState.showHeaderBox
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateHeaderMessageBox: (status) => {
          dispatch(updateHeaderMessageBox(status));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
