import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { RouteMap } from './routes';
import store from './store';
import'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import { setLoading, loginSuccess, logout } from './actions/login/actions'
import setAuthorizationToken, {setAxiosInterceptors} from './utils/setAuthToken'
import { createBrowserHistory } from 'history'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { hydrate, render } from "react-dom";




var jwt = require('jwt-simple');

setAxiosInterceptors();

async function verifySession() {
  store.dispatch(setLoading(true));
  var localData = jwt.decode(localStorage.getItem('token'), process.env.REACT_APP_LOGIN_SECRET_KEY);
  let promise = new Promise((resolve, reject) => {
    axios.post('/verifyPatientSession', { token : localData.token}).then(res => {
      resolve(res)
    })
  });
  promise.then((res) => {
    if(res && res.data) {
      if(!res.data.success) {
        store.dispatch(logout());
      } else {
        store.dispatch(loginSuccess(localData));
        setAuthorizationToken(localData.token);
        store.dispatch(setLoading(false));
      }
    } else {
      store.dispatch(logout());
      store.dispatch(setLoading(false));
    }
    return true
  })
}

if(localStorage.getItem('token')) {
  async function runVerify(){
    await verifySession();
  }
  runVerify();
}

export const history =  createBrowserHistory();
 
const APP = (<Provider store={store}>
  <Router history={history}>
    <RouteMap history={history}/>
    <ToastContainer
        position="bottom-center"
        autoClose={4000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
  </Router>
</Provider>);
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
