import axios from 'axios';
import store from '../store'
import { logout, setLoading } from '../actions/login/actions';
import { toast } from "react-toastify";


export default function setAuthorizationToken(token) {
  
  if (token) {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.common['x-access-token'] = `${token}`;
  } else {
    delete axios.defaults.headers.common['x-access-token'];
  }
}

export function setAxiosInterceptors() {
  axios.defaults.baseURL = process.env.REACT_APP_SERVER_URI;
  axios.defaults.withCredentials = true;
  axios.interceptors.response.use(undefined, error => {
    const { status } = error.response; // add {status, data, config }after status if using
    if(status === 400 || status === 500) {
      store.dispatch(logout())
      store.dispatch(setLoading(false));
      toast('Session expired! Please login again')
    }
  })
}
