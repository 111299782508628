import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LOGIN_VERIFICATION_TYPE } from './config';
export default function(ComposedComponent) {
  class Authenticate extends React.Component {

    UNSAFE_componentWillMount() {
      if (!this.props.isAuthenticated) {
        this.props.history.push('/login');
      }
      if(LOGIN_VERIFICATION_TYPE === 'BOTH_EM') {
        if(!this.props.emailVerified) {
          this.props.history.push('/verifyemail');
        }
        if(!this.props.mobileVerified) {
          this.props.history.push('/verifymobile');
        }
      } else if(LOGIN_VERIFICATION_TYPE === 'ONLY_EMAIL') {
        if(!this.props.emailVerified) {
          this.props.history.push('/verifyemail');
        }
      } else if(LOGIN_VERIFICATION_TYPE === 'ONLY_MOBILE') {
        if(!this.props.mobileVerified) {
          this.props.history.push('/verifymobile');
        }
      }
    }

    UNSAFE_componentWillUpdate(nextProps) {
      if (!nextProps.isAuthenticated) {
        this.props.history.push('/login');
      }
    }

    render() {
      return (
        <ComposedComponent {...this.props} />
      );
    }
  }

  Authenticate.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired
  };


  function mapStateToProps(state) {
    const {isAuthorised, user} = state.get('loginReducer').toJS()
    return {
      isAuthenticated: isAuthorised,
      emailVerified: user ? user.emailVerified : undefined,
      mobileVerified: user ? user.mobileVerified : undefined,
    };
  }

  return connect(mapStateToProps)(Authenticate);
}
