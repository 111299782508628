import * as React from 'react';
import {  Route, Switch } from 'react-router';


import checkAuth from './utils/checkAuth';

import Home from './components/home/index'
import CovidVaccination from './components/covid/vaccination'
import CovidTreatments from './components/covid/treatments'
import Login from './components/login/index'
import Logout from './components/logout';
import Profile from './components/profile';
import Consultation from './components/consultation'
import SubCategory from './components/consultation/subCategory'
import medicalhistory from './components/consultation/medicalHistory'
import prescription from './components/prescription'
import verifyEmail from './components/verifyEmail'
import verifyMobile from './components/verifyMobile';
import PrivacyPolicy from './components/privacypolicy';
import Termsandconditions from './components/termsandconditions';
import AboutUs from './components/aboutus';
import Contactus from './components/contactus';

import Layout from './layouts/default';

// Redirect Components
import VaccinationRedirect from './redirects/vaccination';
import UpperRespiratory from './redirects/upperRespiratory';

// SEO Metadata JSON
import metadata from './metadata.json';

export const RouteMap = (props) => (
  <div>
    <Switch>
      <Route path={process.env.PUBLIC_URL + "/login"} exact={true} component={Login} />
      <Route path={process.env.PUBLIC_URL + "/logout"} exact={true} component={Logout} />
      <Layout {...props}>
        <Route path={process.env.PUBLIC_URL + "/"} exact={true} component={Home} title={metadata.home.title} description={metadata.home.description}/>
        <Route path={process.env.PUBLIC_URL + "/covid-vaccination"} exact={true} component={CovidVaccination}  title={metadata.covidvaccination.title} description={metadata.covidvaccination.description}/>
        <Route path={process.env.PUBLIC_URL + "/covidtreatments"} exact={true} component={CovidTreatments}  title={metadata.covidtreatments.title} description={metadata.covidtreatments.description}/>
        <Route path={process.env.PUBLIC_URL + "/profile"} exact={true} component={Profile} />
        <Route path={process.env.PUBLIC_URL + "/consultation"} exact={true} component={Consultation} />
        <Route path={process.env.PUBLIC_URL + "/subhealthcategory/:healthCategoryId"} exact={true} component={SubCategory} />
        <Route path={process.env.PUBLIC_URL + "/medicalhistory/:healthCategoryId/:subHealthCategoryId"} exact={true} component={medicalhistory} />
        <Route path={process.env.PUBLIC_URL + "/prescription"} exact={true} component={checkAuth(prescription)} />        
        <Route path={process.env.PUBLIC_URL + "/verifyemail"} exact={true} component={checkAuth(verifyEmail)} />       
        <Route path={process.env.PUBLIC_URL + "/verifymobile"} exact={true} component={checkAuth(verifyMobile)} />
        <Route path={process.env.PUBLIC_URL + "/aboutus"} exact={true} component={AboutUs}  title={metadata.aboutus.title} description={metadata.aboutus.description}/>
        <Route path={process.env.PUBLIC_URL + "/contactus"} exact={true} component={Contactus} />
        <Route path={process.env.PUBLIC_URL + "/privacy"} exact={true} component={PrivacyPolicy} />
        <Route path={process.env.PUBLIC_URL + "/termsandconditions"} exact={true} component={Termsandconditions} />
        <Route path={process.env.PUBLIC_URL + "/vaccination-redirect"} exact={true} component={VaccinationRedirect} />        
        <Route path={process.env.PUBLIC_URL + "/upper-respiratory"} exact={true} component={UpperRespiratory} />
      </Layout>
    </Switch>
  </div>
);