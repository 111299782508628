import * as React from 'react';
import { fetchHealthCategories, fetchDiseases, fetchSymptoms, fetchPrescription, updateConsultationData, resetConsultation, setHealthCategory } from '../../actions/consultation/actions';
import {Link} from 'react-router-dom'
import { toast } from "react-toastify";


const connect = require('react-redux').connect;

class MedicalHistory extends React.Component {
    static getDerivedStateFromProps(nextProps, state) {
        if(!state.stateUpdate) {
            const mandatorySymptoms = nextProps.symptoms.filter((v,i,a)=>{if(a.findIndex(t=>(t._id === v._id))===i && v.symptomType === 'mandatory') {return v}});
            let mSymptoms = mandatorySymptoms.length > 0 ?mandatorySymptoms.map(item => item._id).filter((v,i,a)=>a.findIndex(t=>(t._id === v._id))===i) : [];
            return {
                healthCategories: nextProps.healthCategories,
                healthCategory: nextProps.healthCategory,
                subHealthCategories: nextProps.subHealthCategories,
                subHealthCategory: nextProps.subHealthCategory,
                symptoms: nextProps.symptoms.filter((v,i,a)=>a.findIndex(t=>(t._id === v._id))===i),
                diseases: nextProps.diseases.filter((v,i,a)=>a.findIndex(t=>(t._id === v._id))===i),
                prescription: nextProps.prescription,
                symptomsLoading: nextProps.symptomsLoading,
                diseasesLoading: nextProps.diseasesLoading,
                isAuthorised: nextProps.isAuthorised,
                patientType: nextProps.selectedPatientType,
                user: nextProps.user,
                postData: {
                    ...state.postData,
                    symptomsSelected: state.prescriptionInitiated ? state.postData.symptomsSelected : mSymptoms
                }
            }
        } else return null;
    }
    
    constructor(props) {
        super(props);
        this.state = {
            isAuthorised : false,
            healthCategory: '',
            subHealthCategory: '',
            subHealthCategories: [],
            symptoms: [],
            diseases: [],
            noMedicalHistorySelected: false,
            disableSubmit: true,
            prescription: {},
            symptomsLoading: false,
            diseasesLoading: false,
            healthCategories: [],
            currentTab: 0,
            stateUpdate: false,
            prescriptionInitiated: false,
            prescriptionLoaded: false,
            patientType: '',
            user: {},
            selectedDiseaseFor: null,
            postData: {
                healthConditionId: '',
                subHealthConditionId: '',
                symptomsSelected: [],
                medicalHistory: [],
                age: null,
                weight: null,
                drugType: 'Tablet',
                noPastAllergies: false,
                acceptedTerms: true,
            }
        }
        this.handleClick = this.handleClick.bind(this);
        this.checkDisable =  this.checkDisable.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTabClick =  this.handleTabClick.bind(this);
        this.handleDrugType = this.handleDrugType.bind(this);
        this.handleTerms = this.handleTerms.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.computeTableDisabledState = this.computeTableDisabledState.bind(this);
        this.computeSyrupDisabledState = this.computeSyrupDisabledState.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this)
    }

    componentDidMount() {
        if(this.state.healthCategory && this.state.subHealthCategory) {
            const { postData } = this.state;
            this.setState({
                postData: {
                    ...postData,
                    age: this.state.patientType === 'adult' ? 15 : 2,
                    healthConditionId: this.state.healthCategory,
                    subHealthConditionId: this.state.subHealthCategory
                }
            }, () => {
                const { postData } = this.state;
                this.setState({
                    postData : {
                        ...postData,
                        drugType: this.state.postData.age < 7 ? 'Syrup' : 'Tablet' 
                    }
                })
                this.props.onUpdateConsultationData(this.state.postData)
            })
            this.props.onFetchSymptoms({healthConditionId: this.state.healthCategory, subHealthConditionId: this.state.subHealthCategory})
            this.props.onFetchDiseases();
        }
    }

    componentDidUpdate(){
        if(this.state.prescription){
            if(Object.keys(this.state.prescription).length > 0) {
                 this.props.history.push('/prescription');
            }
        }
    }

    componentWillUnmount() {
         this.props.onResetConsultation();
    }

    handleTabClick(e, tab) {
        e.preventDefault();
        this.setState({
            stateUpdate: true,
            currentTab: tab
        })
    }

    handleNextClick(e, tab) {
        e.preventDefault();
        let tabValidated = true;
        let tabValidationMessage = "";
        let currentTab = this.state.currentTab;
        if(currentTab === 0) {
            if(this.state.postData.symptomsSelected.length === 0) {
                tabValidated = false;
                tabValidationMessage = "Please select atleast one symptom to continue"
            }
        } else if(currentTab === 1) {
            if(this.state.postData.medicalHistory.length === 0 && !this.state.noMedicalHistorySelected) {
                tabValidated = false;
                tabValidationMessage = "Please select atleast one medical history to continue"
            }
        }
        if(tabValidated) {
            tabValidationMessage = ""
            this.setState({
                stateUpdate: true,
                currentTab: tab
            })
        } else {
            toast(tabValidationMessage)
        }
    }

    handleDrugType(e, val) {
        e.preventDefault();
        const { postData } = this.state;
        const { age } = postData;
        let drugType = postData.drugType;

        if(age < 8) {
            drugType = 'Syrup'
        } else if(age > 11) {
            drugType = 'Tablet'
        } else {
            drugType = val;
        }
        this.setState({
            stateUpdate: true,
            postData: {
                ...postData,
                drugType
            }
        })
    }

    handleClick(e, value, key){
        e.preventDefault()
        if(key === 'symptomsSelected') {
                const { postData } = this.state;
                const {symptomsSelected} = postData;
                let newSymptomsSelected = [];
                if(this.state.postData.symptomsSelected.includes(value)) {
                    newSymptomsSelected= symptomsSelected.filter(item => item !== value);
                } else {
                    newSymptomsSelected = [
                        ...symptomsSelected,
                        value
                    ]
                }
                this.setState({
                    stateUpdate: true,
                    noMedicalHistorySelected: false,
                    postData: {
                        ...postData,
                        symptomsSelected: [
                            ...newSymptomsSelected
                        ]
                    }
                }, () => {
                    this.checkDisable()
                    this.props.onUpdateConsultationData(this.state.postData)
                })
        }
        else if(key === 'medicalHistory') {
                const { postData } = this.state;
                const {medicalHistory} = postData;
                let newMedicalHistory = [];
                let selectedDiseaseFor = null;
                if(this.state.postData.medicalHistory.includes(value)) {
                    newMedicalHistory= medicalHistory.filter(item => item !== value);
                } else {
                    newMedicalHistory = [
                        ...medicalHistory,
                        value
                    ]
                }
                newMedicalHistory.forEach(item => {
                    if(this.state.diseases.filter(disease => disease.active && disease.type === 'female' && disease._id === item).length ) {
                        selectedDiseaseFor = 'female';
                    } else if(this.state.diseases.filter(disease => disease.active && disease.type === 'male' && disease._id === item).length ) {
                        selectedDiseaseFor = 'male'
                    }
                })
                this.setState({
                    stateUpdate: true,
                    noMedicalHistorySelected: false,
                    selectedDiseaseFor,
                    postData: {
                        ...postData,
                        medicalHistory: newMedicalHistory
                    }
                }, () => {
                    this.checkDisable()
                    this.props.onUpdateConsultationData(this.state.postData)
                })
        } 
        else if(key === 'noMedicalHistorySelected') {
            const { postData } = this.state;
            this.setState({
                stateUpdate: true,
                noMedicalHistorySelected: !this.state.noMedicalHistorySelected,
                postData: {
                    ...postData,
                    medicalHistory: []
                }
            }, () => {
                this.checkDisable()
                this.props.onUpdateConsultationData(this.state.postData)
            })
        }
        else {
            const { postData } = this.state;
            const { drugType } = postData;
            let newDrugType = drugType;
            if(key === 'age' && parseInt(value) > 11) {
                newDrugType = 'Tablet'
            } 
            else if(key === 'age' && parseInt(value) < 8) {
                newDrugType = 'Syrup'
            }
            this.setState({
                stateUpdate: true,
                postData: {
                    ...postData,
                    drugType: newDrugType,
                    [key]: parseInt(value)
                }
            }, () => {
                this.checkDisable()
                this.props.onUpdateConsultationData(this.state.postData)
            })
        }

    }

    handleTerms(e, key) {
        const { postData } = this.state;
        this.setState({
            stateUpdate: true,
            postData: {
                ...postData,
                [key]: e.target.checked
            }
        }, () => {
            this.checkDisable()
            this.props.onUpdateConsultationData(this.state.postData)
        })
    }

    checkDisable(){
        let status = this.state.disableSubmit;
        if((this.state.noMedicalHistorySelected || this.state.postData.medicalHistory.length > 0) && this.state.postData.symptomsSelected.length > 0 && this.state.postData.age > 0 && this.state.postData.weight > 0) {
            status = false;
        } else {
            status = true
        }
        this.setState({
            stateUpdate: true,
            disableSubmit: status
        })
    }

    handleSubmit(event) {
        event.preventDefault()
        if(!this.state.isAuthorised) {
            this.props.history.push('/login')
        } else {
            if(!this.state.disableSubmit) {
                this.setState({
                    stateUpdate: false,
                    prescriptionInitiated: true
                }, () => {
                    this.props.onFetchPrescription({...this.state.postData, patientName: this.state.user.username})
                })
            }
        }
    }

    handleBackClick(event, tab) {
        event.preventDefault();
        this.props.onSetHealthCategory(this.props.match.params.healthCategoryId, this.state.patientType)
        this.props.history.push('/subhealthcategory/'+this.props.match.params.healthCategoryId)
    }

    computeTableDisabledState(){
        let age = this.state.postData.age
        if(age >= 8) {
            return false
        } else return true
    }

    computeSyrupDisabledState(){
        let age = this.state.postData.age
        if(age <= 11) {
            return false
        } else return true
    }

    render() {
        const healthCat = this.state.subHealthCategories.filter(item => item._id === this.state.subHealthCategory)[0]
        const currentTab = this.state.currentTab;
        return (
            <div className="main symptoms">
                <div className="container">
                    <div className="row mt-4">
                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a onClick={e => this.handleTabClick(e, 0)} className={currentTab === 0 ? 'nav-link active' : 'nav-link'} id="v-pills-tab-0" data-toggle="pill" href="#tab0" role="tab" aria-controls="v-pills-0" aria-selected="true"><span>1</span> Symptoms</a>
                            <a onClick={e => this.handleTabClick(e, 1)} className={currentTab === 1 ? 'nav-link active' : 'nav-link'} id="v-pills-tab-1" data-toggle="pill" href="#tab1" role="tab" aria-controls="v-pills-1" aria-selected="false"><span>2</span> Medical History</a>
                            <a onClick={e => this.handleTabClick(e, 2)} className={currentTab === 2 ? 'nav-link active' : 'nav-link'} id="v-pills-tab-2" data-toggle="pill" href="#tab2" role="tab" aria-controls="v-pills-2" aria-selected="false"><span>3</span> Personal Details</a>
                          </div>
                          <div className="tab-content col" id="v-pills-tabContent">
                            <div className="row justify-content-center">
                                <div className="col-12 text-left">
                                    <h1>{healthCat && healthCat.name ? healthCat.name : ''}</h1>
                                </div>
                            </div>
                            <div className={`${currentTab === 0 ? 'tab-pane fade show active' : 'tab-pane fade'}`} id="tab0" role="tabpanel" aria-labelledby="v-pills-tab-0">
                                <h6 className="mb-4">Which Symptoms / Complaints do you have? Please select all that apply to you</h6>
                                {this.state.symptoms.filter(item => item.symptomType === 'mandatory').length > 0 ? 
                                <div className="options-head">
                                    <span></span>
                                </div>
                                : null}
                                <div className="options-items">
                                    {this.state.symptomsLoading && this.state.symptoms.length === 0 ? 
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    : '' }
                                    {this.state.symptoms && this.state.symptoms.map(symptom => {
                                        if(symptom.active && symptom.symptomType === 'mandatory') {
                                            return (
                                                <span key={symptom._id} className={'selected'}>{symptom.name}</span>
                                            )
                                        } else return null
                                    })}
                                    {this.state.symptoms && this.state.symptoms.filter(item => item.active && item.symptomType === 'mandatory').length ? <p>Auto-selected as its required to generate advice</p> : null}
                                </div>
                                {this.state.symptoms.filter(item => item.symptomType === 'mandatory').length > 0 ? 
                                    <div className="options-head">
                                        <span></span>
                                    </div>
                                : null}
                                <div className="options-items">
                                    {this.state.symptomsLoading && this.state.symptoms.length === 0 ? 
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    : '' }
                                    {this.state.symptoms && this.state.symptoms.map(symptom => {
                                        if(symptom.active && symptom.symptomType === 'optional') {
                                            return (
                                                <span key={symptom._id} className={this.state.postData && this.state.postData.symptomsSelected && this.state.postData.symptomsSelected.includes(symptom._id) ? 'selected' : ''} onClick={e => this.handleClick(e, symptom._id, 'symptomsSelected')}>{symptom.name}</span>
                                            )
                                        } else return null
                                    })}
                                </div>
                            </div>
                            <div className={`${currentTab === 1 ? 'tab-pane fade show active' : 'tab-pane fade'}`} id="tab1" role="tabpanel" aria-labelledby="v-pills-tab-1">
                                <h6 className="mb-4">Do you have any of these pre-existing medical conditions. Please select all that apply.</h6>
                                
                                <div className="options-items">
                                    {this.state.diseasesLoading && this.state.diseases.length === 0 ? 
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    : '' }
                                    {this.state.diseases && this.state.diseases.map(disease => {
                                        if(disease.active && disease.type === 'common' && (disease.diseaseFor === this.state.patientType || disease.diseaseFor === 'both')) {
                                            return (
                                                <span key={disease._id} className={this.state.postData && this.state.postData.medicalHistory && this.state.postData.medicalHistory.includes(disease._id) ? 'selected' : ''}  onClick={e => this.handleClick(e, disease._id, 'medicalHistory')}>{disease.name}</span>
                                            )
                                        } else return null;
                                    })}
                                </div>
                                {this.state.patientType !== 'child' ?
                                    <div>
                                        <div className="options-head">
                                            <span>Female</span>
                                        </div>
                                        <div className="options-items">
                                            {this.state.diseasesLoading && this.state.diseases.length === 0 ? 
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            : '' }
                                            {this.state.diseases && this.state.diseases.map(disease => {
                                                if(disease.active && disease.type === 'female') {
                                                    return (
                                                        <span key={disease._id} className={this.state.postData && this.state.postData.medicalHistory && this.state.postData.medicalHistory.includes(disease._id) ? 'selected' : ''}  onClick={e => this.state.selectedDiseaseFor !== 'male' ? this.handleClick(e, disease._id, 'medicalHistory') : null}>{disease.name}</span>
                                                    )
                                                } else return null;
                                            })}
                                        </div>
                                        <div className="options-head">
                                            <span>Male</span>
                                        </div>
                                        <div className="options-items">
                                            {this.state.diseasesLoading && this.state.diseases.length === 0 ? 
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            : '' }
                                            {this.state.diseases && this.state.diseases.map(disease => {
                                                if(disease.active && disease.type === 'male') {
                                                    return (
                                                        <span key={disease._id} className={this.state.postData && this.state.postData.medicalHistory && this.state.postData.medicalHistory.includes(disease._id) ? 'selected' : ''}  onClick={e => this.state.selectedDiseaseFor !== 'female' ? this.handleClick(e, disease._id, 'medicalHistory') : null}>{disease.name}</span>
                                                    )
                                                } else return null;
                                            })}
                                        </div>
                                    </div>
                                 : null}
                                <hr/>
                                <div className="options-items">
                                    <span className={this.state.noMedicalHistorySelected ? 'selected' : ''} onClick={e => this.handleClick(e, true, 'noMedicalHistorySelected')}>Don’t have any of the above listed conditions</span>
                                </div>
                            </div>
                            <div className={`${currentTab === 2 ? 'tab-pane fade show active' : 'tab-pane fade'}`} id="tab2" role="tabpanel" aria-labelledby="v-pills-tab-2">
                                <h5>Select Details</h5>
                                <div className="age-slider">
                                <h4>Age ( in years) <span>{this.state.postData.age}</span></h4>
                                    <div className="d-flex my-4">
                                        <span className="range-number mr-2 mt-1">{this.state.patientType === 'adult' ? 15 : 2}</span>
                                        <form className="range-field w-100">
                                          <input className="custom-range" onChange={e => this.handleClick(e, e.target.value, 'age')} defaultValue={this.state.patientType === 'adult' ? 15 : 2} type="range" min={this.state.patientType === 'adult' ? 15 : 2} max={this.state.patientType === 'adult' ? 100 : 14} />
                                        </form>
                                        <span className="range-number ml-2 mt-1">{this.state.patientType === 'adult' ? 100 : 14}</span>
                                      </div>
                                </div>
                                <div className="age-slider">
                                    <h4>Weight ( in kg) <span>{this.state.postData.weight}</span></h4>
                                    <div className="d-flex my-4">
                                        <span className="range-number mr-2 mt-1">0</span>
                                        <form className="range-field w-100">
                                          <input className="custom-range" onChange={e => this.handleClick(e, e.target.value, 'weight')} defaultValue="0" type="range" min="0" max={this.state.patientType === 'adult' ? 150 : 75} />
                                        </form>
                                        <span className="range-number ml-2 mt-1">{this.state.patientType === 'adult' ? 150 : 75}</span>
                                      </div>
                                </div>
                                {this.state.postData.age && parseInt(this.state.postData.age) < 15 ?
                                <div className="row">
                                    <div className="col-12">
                                        <p className="instructions">8-11 years age group can choose between SYRUP or TABLET ( if formulation available)</p>
                                    </div>
                                    <div className="col-12">
                                        <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                            <label className={this.state.postData.drugType === 'Tablet' ? "btn btn-secondary active" : "btn btn-secondary"}>
                                                <input disabled={this.computeTableDisabledState()} type="radio" name="options" id="option1" onClick={e => this.handleDrugType(e, 'Tablet')}  /> Tablet
                                            </label>
                                            <label className={this.state.postData.drugType === 'Syrup' ? "btn btn-secondary active" : "btn btn-secondary"}>
                                                <input disabled={this.computeSyrupDisabledState()} type="radio" name="options" id="option2" onClick={e => this.handleDrugType(e, 'Syrup')} /> Syrup
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                : null }
                            </div>
                          </div>
                    </div>

                    {this.state.disableSubmit 
                    ? 
                    <div className="row mt-4">
                        <div className="col text-md-right">
                            { currentTab === 1 ?
                            <a onClick={e => this.handleTabClick(e, 0)} className={'btn btn-pink back-btn-bg-color back mr-5'} id="v-pills-tab-0" data-toggle="pill" href="#tab0" role="tab" aria-controls="v-pills-0" aria-selected="false"> Back</a>
                            : currentTab === 2
                            ? <a onClick={e => this.handleTabClick(e, 1)} className={'btn btn-pink back-btn-bg-color back mr-5'} id="v-pills-tab-1" data-toggle="pill" href="#tab1" role="tab" aria-controls="v-pills-1" aria-selected="false">Back</a>
                            : <a onClick={e => this.handleBackClick(e)} className={'btn btn-pink back-btn-bg-color back mr-5'} id="v-pills-tab-0" data-toggle="pill" href="#tab0" role="tab" aria-controls="v-pills-0" aria-selected="false"> Back</a>}
                        </div>
                        <div className="col-auto text-right">
                            {currentTab === 0 
                            ? <a href="javascript:;" onClick={e => this.handleNextClick(e, 1)} className={'btn btn-pink next green '} id="v-pills-tab-1" data-toggle="pill" role="tab" aria-controls="v-pills-1" aria-selected="false"> Next</a>
                            : currentTab === 1
                            ? <a href="javascript:;" onClick={e => this.handleNextClick(e, 2)} className={'btn btn-pink next green '} id="v-pills-tab-2" data-toggle="pill" role="tab" aria-controls="v-pills-2" aria-selected="false">Next</a>
                            : null}
                        </div>
                    </div>
                    : <div className="row mt-4 justify-content-center">
                        <div className="col-12 col-md-6 align-self-end text-md-center">
                            <div className="custom-control custom-radio">
                                <input onChange={e => this.handleTerms(e, 'noPastAllergies')} type="checkbox" id="customRadio1" name="noPastAllergies" className="custom-control-input" checked={this.state.postData.noPastAllergies} />
                                <label className="custom-control-label" htmlFor="customRadio1">No past allergis / side effects with any drugs</label>
                            </div>
                                <button disabled={this.state.disableSubmit || !this.state.postData.noPastAllergies} onClick={this.handleSubmit} className="btn btn-pink mt-3">Generate my doctor grade self treatment advice</button>
                                <p className="mt-2">By clicking this button you agree to the 1)<Link to='/termsandconditions'>Terms of service</Link> 2)<Link to='/privacy'>Privacy Policy</Link> 3) To be contacted via email, SMS, WhatsApp & other channels.</p>
                        </div>
                     </div> 
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const loginState = state.get('loginReducer').toJS()
    const consultationState = state.get('consultationReducer').toJS()
    return {
        isAuthorised: loginState.isAuthorised,
        healthCategory: consultationState.selectedHealthCategory,
        subHealthCategories: consultationState.subHealthCategories,
        subHealthCategory: consultationState.selectedSubHealthCategory,
        symptoms: consultationState.symptoms,
        diseases: consultationState.diseases,
        prescription: consultationState.prescription,
        symptomsLoading: consultationState.symptomsLoading,
        diseasesLoading: consultationState.diseasesLoading,
        healthCategories: consultationState.healthCategories,
        consultationData: consultationState.consultationData,
        prescriptionLoaded: consultationState.prescriptionLoaded,
        selectedPatientType: consultationState.selectedPatientType,
        user: loginState.user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchHealthCat: () => {
          dispatch(fetchHealthCategories());
        },
        onFetchDiseases: () => {
          dispatch(fetchDiseases());
        },
        onFetchSymptoms: (data) => {
          dispatch(fetchSymptoms(data));
        },
        onFetchPrescription: (data) => {
          dispatch(fetchPrescription(data));
        },
        onUpdateConsultationData: (data) => {
          dispatch(updateConsultationData(data));
        },
        onResetConsultation: () => {
          dispatch(resetConsultation());
        },
        onSetHealthCategory: (id, type) => {
          dispatch(setHealthCategory(id, type));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicalHistory);
