import * as React from 'react';
import {Link} from 'react-router-dom'


const connect = require('react-redux').connect;

class Aboutus extends React.Component {
    render() {
        return (
            <div>
                <section className="inner-section blue">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-auto about-intro">
                                <h3>It’s not about us<br/> it’s about YOU</h3>
                                <p>Hello, welcome to Soctor. So glad you are here.</p>
                                <img src="assets/home-screen/azithromycin.svg" />
                                <p>No this not a new candy introduced into market. I am sure you might have popped this one a few times. Didn’t you?</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-section">
                    <div className="container">
                        <div className="row general-section align-items-center justify-content-center col-reverse">
                            <div className="col-md-8 col-12">
                                <div className="content">
                                    <h3 className="desktop">Meet Mr Aam Aadmi</h3>
                                    <p>He is like most of us. Every time he has a cold and throat pain he pops in an antibiotic like azithromycin, painkillers or other commonly available medicines to treat himself before visiting a doctor.</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                    <h3 className="mobile">Meet Mr Aam Aadmi</h3>
                                <img className="about-image" src="assets/home-screen/aam-admi.svg"/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-section grey">
                    <div className="container">
                        <div className="row general-section align-items-center justify-content-center">
                            <div className="col-md-4 col-12">
                                <h3 className="mobile">Meet Mrs Anamika</h3>
                                <img className="about-image" src="assets/home-screen/anamika.svg"/>
                            </div>
                            <div className="col-md-8 col-12">
                                <div className="content lft-30">
                                    <h3 className="desktop">Meet Mrs Anamika</h3>
                                    <p>She contracted a urinary tract infection. Her doctor prescribed the usual treatment: a course of antibiotics. It didn’t work. A few days later, she was found lying on the sofa, shaking, unable to call for help. She was rushed to the hospital & administered another stronger antibiotic, and treated for sepsis, a complication of infection that can be fatal if not treated quickly. It didn’t work either. Five days after hospital admission, she was diagnosed with bacterial infection secondary to multi-drug-resistant E coli, and given colistin, one of the so-called “last resort” antibiotics. Luckily, it worked.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-section">
                    <div className="container">
                        <div className="row general-section align-items-center justify-content-center col-reverse">
                            <div className="col-md-8 col-12">
                                <div className="content">
                                    <h3 className="desktop">And meet me</h3>
                                    <p>I am Dr Vikram Varma Jampana MD, Consultant Physician. Daily I get to see patients (Like Mr Aam Aadmi) who have already self-prescribed a course of antibiotics for cold, cough & throat pain before showing up at my clinic. I also take care of patients (Like Mrs. Anamika) admitted to ICU with hard to treat multidrug-resistant infections. Every time I come across such patients I am worried on how people like Mr Aam admi( & most of us) are unknowingly contributing to Mrs Anamika condition.</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                    <h3 className="mobile">And meet me</h3>
                                <img className="about-image" src="assets/home-screen/vikram.svg"/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-section grey">
                    <div className="container">
                        <div className="row general-section align-items-center justify-content-center">
                            <div className="col-md-4 col-12">
                                <h3 className="mobile">Yes, this can happen to you</h3>
                                <img className="about-image" src="assets/home-screen/suffering.svg"/>
                            </div>
                            <div className="col-md-8 col-12">
                                <div className="content lft-30">
                                    <h3 className="desktop">Yes, this can happen to you</h3>
                                    <p>Imagine yourself lying on a hospital bed suffering from an untreatable infection. This is antibiotic resistance. Antibiotics are used for treating infections caused by bacteria. They are not effective against colds, coughs & sore throats caused by viral infections. Taking antibiotics for the wrong reasons can change bacteria so much that antibiotics don’t work against them. This is called antibiotic resistance. Infections caused by antibiotic-resistant bacteria are difficult to treat.Antibiotics are not like other drugs. They are societal drugs. Misuse of other drugs can cause harm only to the person who consumes it; however, inappropriate antibiotics use affects every one of us, as drug-resistant bacteria can spread from person to person and increase the risk of antibiotic resistance.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-section">
                    <div className="container">
                        <div className="row general-section align-items-center justify-content-center col-reverse">
                            <div className="col-md-8 col-12">
                                <div className="content">
                                    <h3 className="desktop">Antibiotic resistance = Irresponsible self-medication?</h3>
                                    <p>Many factors contribute to antibiotic resistance, most important among them is irresponsible self-medication. Majority of people are not aware of serious health risks of inappropriate self-medication. Given easy availability of drugs and poor access to health care, practice of self-medication is very common. To tackle antibiotic resistance we need to address irresponsible self-medication.</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                    <h3 className="mobile">Antibiotic resistance = Irresponsible self-medication?</h3>
                                <img className="about-image" src="assets/home-screen/antibiotic.svg"/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-section grey">
                    <div className="container">
                        <div className="row general-section align-items-center justify-content-center">
                            <div className="col-md-4 col-12">
                                    <h3 className="mobile">What can you do to help?</h3>
                                <img className="about-image" src="assets/home-screen/you.svg"/>
                            </div>
                            <div className="col-md-8 col-12">
                                <div className="content lft-30">
                                    <h3 className="desktop">What can you do to help?</h3>
                                    <ul>
                                        <li>Prevent infections through hand washing, vaccination and food safety.</li>
                                        <li>Cover your cough, and stay home when you are feeling ill.</li>
                                        <li>Do not expect antibiotics for a viral infection like cold or flu.</li>
                                        <li>Never pressure your health care provider to prescribe an antibiotic.</li>
                                        <li>Take prescribed antibiotics exactly as directed.</li>
                                        <li>Do not skip doses or stop taking an antibiotic early.</li>
                                        <li>Never save antibiotics for the next time you get sick.</li>
                                        <li>Never take antibiotics prescribed for someone else.</li>
                                        <li>Do not self-medicate irresponsibly</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-section">
                    <div className="container">
                        <div className="row general-section align-items-center justify-content-center col-reverse">
                            <div className="col-md-8 col-12">
                                <div className="content">
                                    <h3 className="desktop">What are we doing?</h3>
                                    <p>52% Indians self-medicate on basis of previous experience,advice from unqualified neighbourhood pharmacist &information available online, which is potentially unsafepractice. Irresponsible self-medication causes harm not only to person who consumes medication but also to everyone of us, as negligent use of antibiotics contributes to crisis of antibiotic resistance. We developed Soctor to wean off users from irresponsible self-medication and to make self-care safe & easy. It’s a small step towards responsible, safe selfmedication.</p>
                                    <Link to="/consultation" type="button" className="btn btn-pink mt-5 get-advice-btn" style={{fontSize: '14px'}}> Yes, I want to use RESPONSIBLE self-medication</Link>
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                    <h3 className="mobile">What are we doing?</h3>
                                <img className="about-image" src="assets/home-screen/soctor-hand.png"/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-section blue">
                    <div className="container">
                        <h3 className="section-title">Take Care</h3>
                        <div className="row general-section align-items-center justify-content-center vikram-info">
                            <div className="col-md-6 col-12">
                                <div className="image">
                                    <img src="assets/home-screen/vikram.png"/>
                                </div>
                                <div className="content">
                                    <h3>Dr Vikram Varma Jampana</h3>
                                    <a href="https://www.linkedin.com/in/dr-vikram-varma-jampana/" target="_blank"><i style={{fontSize: '1.5rem',color: '#fff'}} className="bi bi-linkedin"></i></a>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 justify-content-center text-center">
                                <div className="content mt">
                                    <h3>& Team Soctor</h3>
                                    <img className="footer-logo" src="assets/logo-white.svg"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Aboutus);
