import * as React from 'react';
import {Helmet} from "react-helmet";

const connect = require('react-redux').connect;

class MetaData extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Helmet>
                <title>{this.props.title}</title>
                <meta name="description" content={this.props.description} />
                <meta name="keywords" content={this.props.keywords} />
            </Helmet>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaData);
