import * as React from 'react';
import $ from 'jquery';
import { fetchHealthCategories, setHealthCategory, clearPrescription } from '../../actions/consultation/actions';


const connect = require('react-redux').connect;

class Consulation extends React.Component {
    static getDerivedStateFromProps(nextProps, state) {
        return {
            healthCategories: nextProps.healthCategories,
            healthCategoriesLoading: nextProps.healthCategoriesLoading
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            isAuthorised : false,
            healthCategories: [],
            healthCategoriesLoading: false
        }
        this.handleTabMenu = this.handleTabMenu.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.props.onClearPrescription()
        this.props.onFetchHealthCat();
        this.handleTabMenu()
        $(window).resize(() => {
           this.handleTabMenu();
        })
    }

    handleTabMenu(){
        var winWidth = $(window).width();
        $('#nav-children-tab').hide();
        if (winWidth <= 600) {
            $('#nav-children-tab').show();
            $('children-heading').detach();
            
        } else{
            $('#nav-children-tab').hide();
            $('#nav-children').prepend('<h2 class="mt-5 children-heading">Children <span class="pl-2">(Age 2-14 years)</span></h2>');
        }
    }
    handleClick(event, id, type) {
        event.preventDefault();
        this.props.onSetHealthCategory(id, type)
        this.props.history.push('/subhealthcategory/'+id)
    }

    render() {
        return (
            <div className="main adult-child">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <h1>Safe self-medication advice</h1>
                            <span className="secondary-title"><img src="assets/icons/info.svg" alt="" /> Select a specific disease or specific system which is closest to your problem</span>
                        </div>
                    </div>
                    <div className="row mt-3 tab-headers">
                        <div className="col-12">
                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <a className="nav-item nav-link active" id="nav-adult-tab" data-toggle="tab" href="#nav-adult"
                                        role="tab" aria-controls="nav-adult" aria-selected="true">Adult <span className="pl-2">(Age: 15 years & above)</span></a>
                                    <a className="nav-item nav-link" id="nav-children-tab" data-toggle="tab"
                                        href="#nav-children" role="tab" aria-controls="nav-children"
                                        aria-selected="false">Children <span className="pl-2">(Age: 2 -14 years)</span></a>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-adult" role="tabpanel"
                                    aria-labelledby="nav-adult-tab">
                                    <div className="row">
                                        {this.state.healthCategoriesLoading && this.state.healthCategories.length === 0 ? 
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        : '' }
                                        {this.state.healthCategories && this.state.healthCategories.map((healthCat,i) => {
                                            if(healthCat.healthConditionFor==="adult" && healthCat.active) {
                                                return (
                                                    <div key={i} onClick={e => this.handleClick(e, healthCat._id, 'adult')} className="col-12 col-md-4">
                                                        <div className="cards card-adult card-white">
                                                            <img src={healthCat.healthConditionFile} alt="" />
                                                            <h4>{healthCat.name}</h4>
                                                        </div>
                                                    </div>
                                                )
                                            } else return null;
                                        } )}
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-children" role="tabpanel" aria-labelledby="nav-children-tab">
                                    <div className="row">
                                        {this.state.healthCategoriesLoading && this.state.healthCategories.length === 0 ? 
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        : '' }
                                        {this.state.healthCategories && this.state.healthCategories.map((healthCategory,j) => {
                                            if(healthCategory.healthConditionFor==="child" && healthCategory.active) {
                                                return (
                                                    <div key={j} onClick={e => this.handleClick(e, healthCategory._id, 'child')} className="col-12 col-md-4">
                                                        <div className="cards card-adult card-white">
                                                            <img src={healthCategory.healthConditionFile} alt="" />
                                                            <h4>{healthCategory.name}</h4>
                                                        </div>
                                                    </div>
                                                )
                                            } else return null;
                                        } )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const loginState = state.get('loginReducer').toJS()
    const consultationState = state.get('consultationReducer').toJS()
    return {
        isAuthorised: loginState.isAuthorised,
        healthCategories: consultationState.healthCategories,
        healthCategoriesLoading: consultationState.healthCategoriesLoading
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchHealthCat: () => {
          dispatch(fetchHealthCategories());
        },
        onSetHealthCategory: (id, type) => {
          dispatch(setHealthCategory(id, type));
        },
        onClearPrescription: () => {
            dispatch(clearPrescription())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Consulation);
