import * as React from 'react';
import { verifyMobile, otpLoginOperation } from '../../actions/login/actions';
import {validateMobile } from '../../utils/controllers'
import { fetchPrescription } from '../../actions/consultation/actions'


const connect = require('react-redux').connect;

class VerifyMobile extends React.Component {
    static getDerivedStateFromProps(props) {
        return {
            user: props.user,
            consultationData: props.consultationData,
            prescription: props.prescription
        };
      }
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            mobileNumber: '',
            otp: '',
            consultationData: {},
            prescription: {}
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleMobileOtp = this.handleMobileOtp.bind(this);
        this.handleValidateOtp = this.handleValidateOtp.bind(this);
    }
    componentDidUpdate(){
        if(this.state.user && this.state.user.mobileVerified && this.state.consultationData) {
            let prescription = false;
            Object.keys(this.state.consultationData).forEach((key,index) => {
                if(this.state.consultationData[key]){
                    prescription = true
                } else {
                    prescription = false
                }
            })
            if(prescription && Object.keys(this.state.prescription).length === 0) {
                this.props.onFetchPrescription(this.state.consultationData)
            }
            else if(this.state.prescription){
                if(Object.keys(this.state.prescription).length > 0) {
                    this.props.history.push('/prescription');
                }
            } 
            else {
                this.props.history.push('/');
            }
        }
    }
    handleChange = (event) =>{
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    handleMobileOtp = (e) =>{
        e.preventDefault();
        if(validateMobile(this.state.mobileNumber)) {
            this.props.onOtpLoginOperation(this.state.mobileNumber);
        }
    }
    handleValidateOtp = e => {
        e.preventDefault();
        const data = {
            mobileNumber: this.state.mobileNumber,
            otp: this.state.otp,
            id: this.state.user.id
        }
        this.props.onVerifyMobile(data)
    }

    render() {
        const { otpInitiated } = this.props;
        return (
            <div className="container">
                <div className="row justify-content-center my-5">
                    <div className="col-md-4 col-12 text-center">
                        <div className="regi-signin relative">
                            <h2>Add your mobile number below</h2>
                            <div className="form-group">
                                <label htmlFor="basic-url">Mobile No.</label>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="" aria-label=""
                                        aria-describedby="basic-addon2" style={{ borderRight: '0 none' }} name="mobileNumber" value={this.state.mobileNumber} max={10} onChange={this.handleChange}/>
                                    <div className="input-group-append">
                                        <button className="input-group-text" id="basic-addon2" style={{ backgroundColor: 'transparent', border: '1px solid #979797', borderLeft: '0 none', color: '#232782', fontSize: '12px' }} onClick={this.handleMobileOtp}>SEND</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-right">
                                <div className="col-12 mt-3 text-right">
                                    <div className="form-inline float-right">
                                        <div className="form-group">
                                            <label htmlFor="">Enter OTP here</label>
                                            <input disabled={!otpInitiated} type="text" id="" className="form-control" aria-describedby="" placeholder="****" name='otp' onChange={this.handleChange} value={this.state.otp} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center mt-3">
                                <div className="col-md-4 col-12 mt-3 text-center">
                                    <button type="button" onClick={this.handleValidateOtp} className="btn btn-pink">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const loginState = state.get('loginReducer').toJS()
    const consultationState = state.get('consultationReducer').toJS()
    return {
        user: loginState.user,
        otpInitiated: loginState.otpInitiated,
        consultationData: consultationState.consultationData,
        prescription: consultationState.prescription
    };
}

const mapDispatchToProps = dispatch => {
    return {
        // toggleTodo: todoId => dispatch(toggleTodo(todoId))
        onOtpLoginOperation: (data) => {
            dispatch(otpLoginOperation(data));
        },
        onVerifyMobile: (data) => {
            dispatch(verifyMobile(data));
        },
        onFetchPrescription: (data) => {
          dispatch(fetchPrescription(data));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyMobile);
