import * as React from 'react';
import { getPatientDetails } from '../../actions/profile/actions';
const connect = require('react-redux').connect;

class Profile extends React.Component {
    static getDerivedStateFromProps(nextProps, state) {
        if(nextProps.id !== state.id) {
            return {
                id: nextProps.id
            }
        }
        if(nextProps.user && nextProps.user._id !== state.user._id) {
            return {
                user: nextProps.user
            }
        }
        return null
    }

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            user: {}
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
    }

    componentDidMount() {
        this.props.onGetPatientDetails(this.state.id)
    }

    handleChange(e) {
        e.preventDefault();
        let user = Object.assign({}, this.state.user, {
            [e.target.name]: e.target.value
        })
        this.setState({
            user
        })
    }
    handleLogout(e) {
        e.preventDefault();
        this.props.history.push('/logout')
    }
    render() {
        return (
            <div className="main profile">
                <div className="container">
                    <div className="row mt-4">
                        <div className="col-md-4 col-12">
                            <div className="form-group">
                                <label htmlFor="">Name</label>
                                <input type="text" className="form-control" defaultValue={this.state.user ? this.state.user.username : ""} name="username" onChange={this.handleChange}/>
                              </div>
                        </div>
                        {this.state.user && this.state.user.mobileNumber && this.state.user.mobileVerified ?
                            <div className="col-md-4 col-12">
                                <div className="form-group">
                                    <label htmlFor="">Mobile</label>
                                    <input type="text" readOnly={true} className="form-control" defaultValue={this.state.user ? this.state.user.mobileNumber : ""} aria-describedby="" />
                                </div>
                            </div>
                        : ""}
                        {this.state.user && this.state.user.email && this.state.user.emailVerified ?
                            <div className="col-md-4 col-12">
                                <div className="form-group">
                                    <label htmlFor="">Email</label>
                                    <input type="email" readOnly={true} className="form-control"  defaultValue={this.state.user ? this.state.user.email : ""} aria-describedby="" />
                                </div>
                            </div>
                         : ""}
                    </div>
                    <div className="row justify-content-center mt-3">
                        <div className="col-md-4 col-12 mt-3 text-center">
                            <button type="button" onClick={this.handleLogout} className="btn btn-pink">Logout</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const loginState = state.get('loginReducer').toJS()
    const profileState = state.get('profileReducer').toJS()
    return {
        id: loginState.user && loginState.user.id ? loginState.user.id : '',
        user: profileState.patient
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onGetPatientDetails: (data) => {
          dispatch(getPatientDetails(data));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
