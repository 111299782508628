import * as React from 'react';
import { GoogleLogin } from 'react-google-login';
import { verifyEmail } from '../../actions/login/actions';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'


const connect = require('react-redux').connect;

class VerifyEmail extends React.Component {
    static getDerivedStateFromProps(props) {
        return {
            user: props.user,
            consultationData: props.consultationData,
            prescription: props.prescription
        };
      }
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            prescription: {},
            consultationData: {}
        }
        this.responseGoogle = this.responseGoogle.bind(this);
        this.responseFacebook = this.responseFacebook.bind(this);
    }
    responseGoogle = (response) => {
        var data = {
            accessToken: response.tokenId,
            provider: "google",
            id: this.state.user.id
        }
        this.props.onVerifyEmail(data)
    }
    responseFacebook = (response) => {
        // send accesstoken in the below payload
        var data = {
            accessToken: "",
            provider: "facebook",
            id: this.state.user.id
        }
        this.props.onVerifyEmail(data);
    }
    componentDidUpdate(){
        if(this.state.user && this.state.user.emailVerified && this.state.consultationData) {
            let prescription = false;
            Object.keys(this.state.consultationData).forEach((key,index) => {
                if(this.state.consultationData[key]){
                    prescription = true
                } else {
                    prescription = false
                }
            })
            
            if(prescription && Object.keys(this.state.prescription).length === 0) {
                this.props.onFetchPrescription(this.state.consultationData)
            }
            else if(this.state.prescription){
                if(Object.keys(this.state.prescription).length > 0) {
                    this.props.history.push('/prescription');
                }
            } else {
                this.props.history.push('/');
            }
        }
    }

    render() {
        return (
            <div className="container">
                <div className="row justify-content-center my-5">
                    <div className="col-md-4 col-12 text-center">
                        <div className="regi-signin relative">
                            <h2>Add your email by login with any of social accounts below</h2>
                            <div className="form-group">
                                <GoogleLogin
                                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                    buttonText="Login"
                                    render={renderProps => (
                                        <button onClick={renderProps.onClick} disabled={renderProps.disabled} type="button" className="btn btn-outline">Add with your Google account </button>
                                        )}
                                    onSuccess={this.responseGoogle}
                                    onFailure={this.responseGoogle}
                                    cookiePolicy={'single_host_origin'}
                                />

                                {/* <FacebookLogin
                                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    render={({onClick}) => (
                                    <button onClick={onClick} type="button" className="btn btn-outline mt-3">Add with your Facebook account </button>
                                    )}
                                    callback={this.responseFacebook} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const loginState = state.get('loginReducer').toJS()
    const consultationState = state.get('consultationReducer').toJS()
    return {
        user: loginState.user,
        consultationData: consultationState.consultationData,
        prescription: consultationState.prescription
    };
}

const mapDispatchToProps = dispatch => {
    return {
        // toggleTodo: todoId => dispatch(toggleTodo(todoId))
        onVerifyEmail: (data) => {
          dispatch(verifyEmail(data));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
