export const actionTypes = {
    USER_LOGIN: 'LOGIN/USER_LOGIN',
    USER_LOGIN_SUCCESS: 'LOGIN/USER_LOGIN_SUCCESS',
    USER_LOGIN_ERROR: 'LOGIN/USER_LOGIN_ERROR',
    USER_LOGOUT: 'LOGIN/USER_LOGOUT',
    USER_LOGOUT_SUCCESS: 'LOGIN/USER_LOGOUT_SUCCESS',
    SET_LOADING: 'LOGIN/SET_LOADING',
    HANDLE_GOOGLE_LOGIN: 'LOGIN/HANDLE_GOOGLE_LOGIN',
    HANDLE_FACEBOOK_LOGIN: 'LOGIN/HANDLE_FACEBOOK_LOGIN',
    HANDLE_OTP_LOGIN: 'LOGIN/HANDLE_OTP_LOGIN',
    OTP_VALIDATION: 'LOGIN/OTP_VALIDATION',
    OTP_VALIDATION_SUCCESS: 'LOGIN/OTP_VALIDATION_SUCCESS',
    OTP_VALIDATION_FAIL: 'LOGIN/OTP_VALIDATION_FAIL',
    OTP_INITIATED: 'LOGIN/OTP_INITIATED',
    OTP_INTITIALISATION_FAILED: 'LOGIN/OTP_INTITIALISATION_FAILED',
    VALIDATE_OTP: 'LOGIN/VALIDATE_OTP',
    UPDATE_LOG_MESSAGE: 'LOGIN/UPDATE_LOG_MESSAGE',
    VERIFY_EMAIL: 'LOGIN/VERIFY_EMAIL',
    VERIFY_EMAIL_SUCCESS: 'LOGIN/VERIFY_EMAIL_SUCCESS',
    VERIFY_MOBILE_NUMBER: 'LOGIN/VERIFY_MOBILE_NUMBER',
    VERIFY_MOBILE_NUMBER_SUCCESS: 'LOGIN/VERIFY_MOBILE_NUMBER_SUCCESS',
    UPDATE_HEADER_MESSAGE_BOX: 'LOGIN/UPDATE_HEADER_MESSAGE_BOX',
};
  