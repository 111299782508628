import { fromJS } from 'immutable';
import { actionTypes as at } from '../actions/login/constants'

const initialState = fromJS({
    isAuthorised: false,
    message: "",
    otpInitiated: false,
    otpValidationStatus: false,
    user: {},
    loading: false,
    showHeaderBox: true
});

export default (state = initialState, action) => {
  switch (action.type) {
    case at.SET_LOADING:
      return state
        .set('loading', action.payload)

    case at.USER_LOGIN_SUCCESS:
      return state
        .set('isAuthorised', action.payload.success)
        .set('user', action.payload.data)

    case at.USER_LOGOUT_SUCCESS:
        return state
        .set('isAuthorised', false)
        .set('user', undefined)
        .set('otpInitiated', false)
        .set('otpValidationStatus', false)
      
    case at.UPDATE_LOG_MESSAGE:
      return state
      .set('message', action.payload)

    case at.OTP_VALIDATION_SUCCESS:
      return state
      .set('otpValidationStatus', true)

    case at.OTP_VALIDATION_FAIL:
      return state
      .set('otpValidationStatus', false)

    case at.OTP_INITIATED:
      return state
      .set('otpInitiated', true)

    case at.OTP_INTITIALISATION_FAILED:
      return state
      .set('otpInitiated', false)

    case at.VERIFY_EMAIL_SUCCESS:
      return state
      .set('user', action.payload)

    case at.VERIFY_MOBILE_NUMBER_SUCCESS:
      return state
      .set('user', action.payload)

    case at.UPDATE_HEADER_MESSAGE_BOX:
      return state
      .set('showHeaderBox', action.payload)
    
    default:
      return state;
  }
};
