export const actionTypes = {
    FETCH_HEALTH_CATEGORIES: 'CONSULTATION/FETCH_HEALTH_CATEGORIES',
    FETCH_HEALTH_CATEGORIES_SUCCESS: 'CONSULTATION/FETCH_HEALTH_CATEGORIES_SUCCESS',
    FETCH_HEALTH_CATEGORIES_ERROR: 'CONSULTATION/FETCH_HEALTH_CATEGORIES_ERROR',
    FETCH_SUB_HEALTH_CATEGORIES: 'CONSULTATION/FETCH_SUB_HEALTH_CATEGORIES',
    FETCH_SUB_HEALTH_CATEGORIES_SUCCESS: 'CONSULTATION/FETCH_SUB_HEALTH_CATEGORIES_SUCCESS',
    FETCH_SUB_HEALTH_CATEGORIES_ERROR: 'CONSULTATION/FETCH_SUB_HEALTH_CATEGORIES_ERROR',
    SET_HEALTH_CATEGORY: 'CONSULTATION/SET_HEALTH_CATEGORY',
    SET_SUB_HEALTH_CATEGORY: 'CONSULTATION/SET_SUB_HEALTH_CATEGORY',
    FETCH_DISEASES: 'CONSULTATION/FETCH_DISEASES',
    FETCH_DISEASES_SUCCESS: 'CONSULTATION/FETCH_DISEASES_SUCCESS',
    FETCH_DISEASES_ERROR: 'CONSULTATION/FETCH_DISEASES_ERROR',
    FETCH_SYMPTOMS: 'CONSULTATION/FETCH_SYMPTOMS',
    FETCH_SYMPTOMS_SUCCESS: 'CONSULTATION/FETCH_SYMPTOMS_SUCCESS',
    FETCH_SYMPTOMS_ERROR: 'CONSULTATION/FETCH_SYMPTOMS_ERROR',
    FETCH_PRESCRIPTION: 'CONSULTATION/FETCH_PRESCRIPTION',
    FETCH_PRESCRIPTION_SUCCESS: 'CONSULTATION/FETCH_PRESCRIPTION_SUCCESS',
    CLEAR_PRESCRIPTION: 'CONSULTATION/CLEAR_PRESCRIPTION',
    EMAIL_PRESCRIPTION: 'CONSULTATION/EMAIL_PRESCRIPTION',
    EMAIL_PRESCRIPTION_SUCCESS: 'CONSULTATION/EMAIL_PRESCRIPTION_SUCCESS',
    UPDATE_CUSTOMER_CONSULTATION_DATA: 'CONSULTATION/UPDATE_CUSTOMER_CONSULTATION_DATA',
    RESET_PRESCRIPTION: 'CONSULTATION/RESET_PRESCRIPTION',
    DOWNLOAD_PRESCRIPTION: 'CONSULTATION/DOWNLOAD_PRESCRIPTION',
    DOWNLOAD_PRESCRIPTION_SUCCESS: 'CONSULTATION/DOWNLOAD_PRESCRIPTION_SUCCESS',
};