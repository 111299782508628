import * as React from 'react';
import { fetchSubHealthCategories, setSubHealthCategory } from '../../actions/consultation/actions';
import $ from 'jquery';
const connect = require('react-redux').connect;

class SubCategory extends React.Component {
    static getDerivedStateFromProps(nextProps, state) {
        return {
            healthCategory: nextProps.healthCategory,
            subHealthCategories: nextProps.subHealthCategories,
            subHealthCategory: nextProps.subHealthCategory,
            subHealthCategoriesLoading: nextProps.subHealthCategoriesLoading,
            healthCategories: nextProps.healthCategories
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            isAuthorised : false,
            healthCategory: '',
            subHealthCategory: '',
            subHealthCategories: [],
            subHealthCategoriesLoading: false,
            healthCategories: []
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this)
    }

    componentDidMount() {
        if(!this.state.healthCategory) {
            this.props.history.push('/consultation')
        }
        else if(this.state.healthCategory !== this.props.match.params.healthCategoryId) {
            this.props.history.push('/consultation')
        } else {
            this.props.onFetchSubHealthCategories(this.state.healthCategory);
        }
    }
    componentDidUpdate(){
        if(!this.state.healthCategory) {
            this.props.history.push('/consultation')
        }
        $('.cards').on('mouseenter',function(){
            if($(this).hasClass('card-white')) {
                $(this).removeClass('card-white');
                $(this).addClass('card-blue')
            }
        })
        $('.cards').on('mouseleave',function(){
            if($(this).hasClass('card-blue')) {
                $(this).removeClass('card-blue');
                $(this).addClass('card-white')
            }
        })
    }

    handleClick(event, id) {
        event.preventDefault();
        this.props.onSetSubHealthCategory(id)
        this.props.history.push('/medicalhistory/'+this.state.healthCategory+'/'+id);
    }

    handleBackClick(event) {
        event.preventDefault();
        this.props.history.push('/consultation')
    }

    render() {
        return (
            <div className="main ssma">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <h1>Safe self-medication advice</h1>
                            <span className="secondary-title"><img src="assets/icons/info.svg" alt="" /> Select a specific disease or specific system which is closest to your problem</span>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            {this.state.healthCategories.map(item => {
                                if(item._id === this.state.healthCategory) {
                                    return (
                                        <h2 key={item._id}> <img src={process.env.REACT_APP_SERVER_URI +'/' + item.healthConditionFile} alt="" /> {item.name}</h2>
                                    );
                                } else return null;
                            })}
                        </div>
                        <div className="col-12">
                            <div className="row">
                                {this.state.subHealthCategoriesLoading && this.state.subHealthCategories.length === 0 ? 
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                : '' }
                                {this.state.subHealthCategories && this.state.subHealthCategories.map(healthCat => {
                                    if(healthCat.active) {
                                        return (
                                            <div key={healthCat._id} onClick={e => this.handleClick(e, healthCat._id)} className="col-md-4 col-12">
                                                <div className="cards card-white">
                                                    <h4>
                                                        <span>{healthCat.name}</span>
                                                        {healthCat.patientSlangs && healthCat.patientSlangs.join(', ')}
                                                    </h4>
                                                    <span className="pt-5">Advice on managing symptoms of these conditions</span>
                                                </div>
                                            </div>
                                        )
                                    } else return null;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const loginState = state.get('loginReducer').toJS()
    const consultationState = state.get('consultationReducer').toJS()
    return {
        isAuthorised: loginState.isAuthorised,
        healthCategory: consultationState.selectedHealthCategory,
        subHealthCategories: consultationState.subHealthCategories,
        subHealthCategory: consultationState.selectedSubHealthCategory,
        subHealthCategoriesLoading: consultationState.subHealthCategoriesLoading,
        healthCategories: consultationState.healthCategories,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchSubHealthCategories: (id) => {
          dispatch(fetchSubHealthCategories(id));
        },
        onSetSubHealthCategory: (id) => {
          dispatch(setSubHealthCategory(id));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubCategory);
