import * as React from 'react';
import {Link} from 'react-router-dom'

const connect = require('react-redux').connect;

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-md-6 col-12 copyright order-2 order-md-1 text-md-left text-center">
                            copyrights @ Soctor {new Date().getFullYear()}
                        </div>
                        <div className="col-md-6 col-12 text-center text-md-right order-1 order-md-2">
                            <div className="row">
                                <div className="col-12">
                                    <Link to={"/aboutus"}>About Us</Link>
                                    <Link to={"/privacy"}>Privacy</Link>
                                    <Link to={"/termsandconditions"}>Terms and Conditions</Link>
                                    <Link to={"/contactus"}>Contact Us</Link>
                                </div>
                                {/* <div className="col-12 mt-md-5 mt-3 mb-3 mb-md-0">
                                    <a href="/"><img src="/assets/icons/facebook.svg" alt="" /></a>
                                    <a href="/"><img src="/assets/icons/linkedIn.svg" alt="" /></a>
                                    <a href="/"><img src="/assets/icons/twitter.svg" alt="" /></a>
                                    <a href="/"><img src="/assets/icons/intagram.svg" alt="" /></a>
                                    <a href="/"><img src="/assets/icons/youtube.svg" alt="" /></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
