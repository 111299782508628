import { actionTypes as at } from '../actions/consultation/constants';
import { fetchHealthCategoriesSuccess, fetchSubHealthCategoriesSuccess, fetchDiseasesSuccess, fetchSymptomsSuccess, fetchPrescriptionSuccess, emailPrescriptionSuccess, downloadPrescriptionSuccess } from '../actions/consultation/actions';
import { takeEvery, put } from 'redux-saga/effects';
import axios from 'axios';
import { saveAs } from 'file-saver';

function* getHealthCategories(){
  try {
    const { data={} } = yield axios.get("/patients/healthConditions/all");
    if(data.success) {
        yield put(fetchHealthCategoriesSuccess(data.data));
    }
  }catch(err) {
    console.log(err);
    return null;
  }
}

function* getSubHealthCategories(data){
    const { payload } = data;
    try {
      const { data={} } = yield axios.get("/patients/healthCondition/"+payload+"/subHealthConditions");
      if(data.success) {
          yield put(fetchSubHealthCategoriesSuccess(data.data));
      }
    }catch(err) {
      console.log(err);
      return null;
    }
  }


function* getDiseases(){
    try {
        const { data={} } = yield axios.get("/patients/diseases/all");
        if(data.success) {
            yield put(fetchDiseasesSuccess(data.data));
        }
    }catch(err) {
        console.log(err);
        return null;
    }
}



function* getSymptoms(data){
    const { payload } = data;
    const { healthConditionId, subHealthConditionId} = payload;
    try {
        const { data={} } = yield axios.get("/patients/healthCondition/"+healthConditionId+"/subHealthCondition/"+subHealthConditionId+"/symptoms");
        if(data.success) {
            yield put(fetchSymptomsSuccess(data.data));
        }
    }catch(err) {
        console.log(err);
        return null;
    }
}

function* getPrescription(data){
    const { payload } = data;
    try {
        const { data={}, success } = yield axios.post("/patients/prescription", payload);
        if(data.success) {
            yield put(fetchPrescriptionSuccess(data.data));
        }
    }catch(err) {
        console.log(err);
        return null;
    }
}
  

function* emailPrescriptionSaga(data) {
  const { payload } = data;
  try {
    const { data={} } = yield axios.post("/patients/sendPrescription", payload, {
      responseType: 'blob', 
      headers: {
        'Accept': 'application/pdf'
      }
  });
  if(payload.emailAddress) {
    const text = yield new Response(data).json()
    if(text.success ){
      yield put(emailPrescriptionSuccess(data.success));
    }
  } else if(data){
    const pdfBlob = new Blob([data], { type: 'application/pdf' });
    saveAs(pdfBlob, 'soctor-prescription-'+Date.now()+'.pdf');
    yield put(downloadPrescriptionSuccess(true));
  }
  } catch(err) {
    return err
  }
}

function* downloadPrescriptionSaga(data) {
  const { payload } = data;
  try {
    const { data } = yield axios.post("/patients/downloadPrescription", payload, {
          responseType: 'blob', 
          headers: {
            'Accept': 'application/pdf'
          }
      });
    if(data){
      const pdfBlob = new Blob([data], { type: 'application/pdf' });
      saveAs(pdfBlob, 'soctor-prescription-'+Date.now()+'.pdf');
    }
  } catch(err) {
    return err
  }
}

export default function* consultationSaga() {
  try {
    yield [
        takeEvery(at.FETCH_HEALTH_CATEGORIES, getHealthCategories),
        takeEvery(at.FETCH_SUB_HEALTH_CATEGORIES, getSubHealthCategories),
        takeEvery(at.FETCH_DISEASES, getDiseases),
        takeEvery(at.FETCH_SYMPTOMS, getSymptoms),
        takeEvery(at.FETCH_PRESCRIPTION, getPrescription),
        takeEvery(at.EMAIL_PRESCRIPTION, emailPrescriptionSaga),
        takeEvery(at.DOWNLOAD_PRESCRIPTION, downloadPrescriptionSaga),
    ];
  } catch (error) {
    return;
  }
}
