import * as React from 'react';
import {Link} from 'react-router-dom'
import { GoogleLogin } from 'react-google-login';
import { googleLoginOperation, facebookLoginOperation, otpLoginOperation, validateOtp, otpInitialisationFail } from '../../actions/login/actions';
import { fetchPrescription } from '../../actions/consultation/actions'
import {validateMobile, debounce } from '../../utils/controllers'
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import OtpInput from 'react-otp-input';
import * as _ from 'lodash';

const connect = require('react-redux').connect;

let timerInterval = undefined;

class Login extends React.Component {
    static getDerivedStateFromProps(props, state) {
        if(props.isAuthorised) {
            if(props.consultationData) {
                let prescription = false;
                Object.keys(props.consultationData).forEach((key,index) => {
                    if(props.consultationData[key]){
                        prescription = true
                    } else {
                        prescription = false
                    }
                })
                if(prescription && Object.keys(props.prescription).length === 0) { 
                    if(!state.prescriptionFetched)  {    
                        props.onFetchPrescription({...props.consultationData, patientName: props.user.username})                  
                        return {
                            prescriptionFetched: true
                        };
                    }    
                }
                else if(!_.isEmpty(props.prescription)){
                    if(Object.keys(props.prescription).length > 0) {
                         props.history.push('/prescription');
                         return null;
                    }
                }
                else {
                    props.history.push('/');
                }
            }
            else {
                props.history.push('/');
            }
        }
        return null;
      }
    constructor(props) {
        super(props);
        this.responseGoogle = this.responseGoogle.bind(this);
        this.responseFacebook = this.responseFacebook.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleMobileOtp = this.handleMobileOtp.bind(this);
        this.handleValidateOtp = this.handleValidateOtp.bind(this);
        this.handleOtpChange = this.handleOtpChange.bind(this);
        this.handleNumber = this.handleNumber.bind(this);
        this.handleCancelOtp = this.handleCancelOtp.bind(this);
        this.state ={
            mobileNumber: '',
            otp: '',
            timer: 30,
            timerStarted: false,
            prescriptionFetched: false,
            socialLoginStarted: false,
            mobileLoginStarted: false
        }
    }
    componentDidMount() {
        clearInterval(timerInterval)
        this.setState({
            timer: 30,
            timerStarted: false
        })
    }
    componentDidUpdate() {
        if(this.props.otpInitiated && !this.state.timerStarted) {
            timerInterval = setInterval(() => {
                let timer = this.state.timer;
                if(timer === 0 || !timer) {
                    clearInterval(timerInterval);
                    this.setState({
                        timerStarted: false
                    })
                } else {
                    timer = timer - 1;
                    this.setState({
                        timer,
                        timerStarted: true
                    })
                }
            }, 1000);
        }
    }
    componentWillUnmount() {
        if(this.state.timerStarted) {
            this.props.onOtpInitialisationFail()
            clearInterval(timerInterval)
            this.setState({
                timer: 30,
                timerStarted: false
            })
        }
    }
    responseGoogle = (response) => {
        var data = {
            accessToken: response.tokenId,
            provider: "google"
        }
        this.props.onGoogleLoginOperation(data)
        this.setState({
            socialLoginStarted: false
        })
    }
    responseFacebook = (response) => {
        // send accesstoken in the below payload
        var data = {
            accessToken: response.accessToken,
            provider: "facebook"
        }
        this.props.onFacebookLoginOperation(data);
        this.setState({
            socialLoginStarted: false
        })
    }

    handleChange = (event) =>{
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    handleOtpChange = (val) =>{
        this.setState({
            otp: val
        })
    }
    handleMobileOtp = (e) =>{
        e.preventDefault();
        if(validateMobile(this.state.mobileNumber)) {
            clearInterval(timerInterval)
            this.setState({
                timer: 30,
                timerStarted: false,
                mobileLoginStarted: true
            }, ()=> {
                this.props.onOtpLoginOperation(this.state.mobileNumber);
            })
        }
    }
    handleValidateOtp = e => {
        e.preventDefault();
        const data = {
            mobileNumber: this.state.mobileNumber,
            otp: this.state.otp
        }
        this.props.onValidateOtp(data)
    }
    handleNumber(evt) {
        if ([8, 46, 37, 39, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 35, 36].indexOf(evt.keyCode || evt.which) == -1)
        {
            evt.returnValue = false;
            if(evt.preventDefault){
                evt.preventDefault();
            }
        }
    }
    handleCancelOtp(){
        this.props.onOtpInitialisationFail()
        clearInterval(timerInterval)
        this.setState({
            timer: 30,
            timerStarted: false,
            mobileLoginStarted: false
        })
        
    }
    render() {
        const { otpInitiated } = this.props;
        const { socialLoginStarted, mobileLoginStarted } = this.state;
        if(socialLoginStarted) {
            return (
                <div className="spinner-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <strong>Please wait...</strong>
                </div>
            )
        } else {
            return (
                <div className="container regi-signin">
                    <div className="row justify-content-center mt-md-0 mt-5">
                        <div className="col-auto">
                            <Link to="/"><img src="assets/logo.svg" alt="" width="161" height="40" /></Link>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <h3 className="login-title">Login/Signup</h3>
                            <div className="tab-pane fade show active" id="nav-register" role="tabpanel" aria-labelledby="nav-register-tab">
                                <div className="row justify-content-center">
                                    <div className="col-md-4 col-12 text-center">
                                        <div className="form-group">
                                            <GoogleLogin
                                                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                                buttonText="Login"
                                                render={renderProps => (
                                                    <button onClick={() => {
                                                        this.setState({
                                                            socialLoginStarted: true
                                                        }, () => {
                                                            renderProps.onClick()
                                                        })
                                                        }
                                                    } disabled={renderProps.disabled || mobileLoginStarted} type="button" className="btn btn-outline"><img className="login-logo" src="assets/icons/google-logo.svg" />Continue with your Google account </button>
                                                    )}
                                                onSuccess={this.responseGoogle}
                                                onFailure={this.responseGoogle}
                                                cookiePolicy={'single_host_origin'}
                                            />
    
                                            {/* <FacebookLogin
                                                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                                autoLoad={false}
                                                fields="name,email,picture"
                                                render={({onClick}) => (
                                                <button onClick={() => {
                                                        this.setState({
                                                            socialLoginStarted: true
                                                        }, () => {
                                                            onClick()
                                                        })
                                                    }} disabled={mobileLoginStarted} type="button" className="btn btn-outline mt-3"><img className="login-logo" src="assets/icons/facebook-logo.svg" />Continue with your Facebook account </button>
                                                )}
                                                callback={this.responseFacebook} /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-auto my-4">
                                        <span className="or">OR</span>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-md-4 col-10">
                                        {otpInitiated 
                                        ?
                                        (
                                            <div className="row justify-content-right">
                                                <div className="col-12 mt-3">
                                                        <div className="form-group align-center">
                                                            <h5 className="verify-otp-title">Verify with OTP</h5>
                                                            <label>Sent to {this.state.mobileNumber}</label>
                                                            <OtpInput
                                                                containerStyle="otpContainer"
                                                                isInputNum={true}
                                                                name='otp'
                                                                value={this.state.otp}
                                                                onChange={this.handleOtpChange}
                                                                numInputs={6}
                                                                separator={<span>-</span>}
                                                                isDisabled={!otpInitiated}
                                                            />
                                                            {this.state.timer === 0 
                                                            ? (<span onClick={this.handleMobileOtp} className="resend-otp">Resend OTP</span>)
                                                            : (<div className="resend-otp-div">Resend OTP in {this.state.timer}</div>)}
                                                        </div>
                                                </div>
                                                <div className="col-md-12 col-12 mt-3 text-center">
                                                    <button type="button" style={{marginRight: '20px'}} onClick={this.handleCancelOtp} className="btn btn-pink back-btn-bg-color">Cancel</button>
                                                    <button disabled={!otpInitiated} type="button" onClick={this.handleValidateOtp} className="btn btn-pink">Verify</button>
                                                </div>
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="row justify-content-right">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="basic-url">Mobile No.</label>
                                                        <div className="input-group mb-3">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text" id="basic-addon1">+91</span>
                                                            </div>
                                                            <input type="email" className="form-control" placeholder="" aria-label=""
                                                                aria-describedby="basic-addon2" style={{ borderLeft: '0 none' }} pattern="[1-9]{1}[0-9]{9}" name="mobileNumber" value={this.state.mobileNumber} maxLength="10" max={10} onKeyPress={this.handleNumber} onChange={this.handleChange}/>
                                                        </div>
                                                        <div className="row justify-content-center mt-3">
                                                            <div className="col-md-4 col-12 mt-3 text-center">
                                                                <button type="button" onClick={this.handleMobileOtp} className="btn btn-pink">Continue</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) 
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        
    }
}

function mapStateToProps(state) {
    const loginState = state.get('loginReducer').toJS()
    const consultationState = state.get('consultationReducer').toJS()
    return {
        isAuthorised: loginState.isAuthorised,
        message: loginState.message,
        otpInitiated: loginState.otpInitiated,
        consultationData: consultationState.consultationData,
        prescription: consultationState.prescription,
        user: loginState.user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        // toggleTodo: todoId => dispatch(toggleTodo(todoId))
        onGoogleLoginOperation: (data) => {
          dispatch(googleLoginOperation(data));
        },
        onFacebookLoginOperation: (data) => {
          dispatch(facebookLoginOperation(data));
        },
        onOtpLoginOperation: (data) => {
          dispatch(otpLoginOperation(data));
        },
        onValidateOtp: (data) => {
          dispatch(validateOtp(data));
        },
        onFetchPrescription: (data) => {
          dispatch(fetchPrescription(data));
        },
        onOtpInitialisationFail: () => {
            dispatch(otpInitialisationFail())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
