import { combineReducers } from 'redux-immutable';

import loginReducer from './reducers/login'
import profileReducer from './reducers/profile'
import consultationReducer from './reducers/consultation'

export const state = combineReducers({
    loginReducer,
    profileReducer,
    consultationReducer
});
